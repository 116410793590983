import React, { useState, useEffect } from "react";
import { Divider, Drawer,List, ListItem, Checkbox } from "@mui/material";
import { Box } from "@mui/system";
export const DrillSelectionBox = ({ openDrillSelectionBox, handleDrillSelectionClose, selectedSession,
    handleSaveDrill }) => {
    const [drills, setDrills] = useState([]);
    useEffect(() => {
        if (selectedSession &&
            Array.isArray(selectedSession)) {
            let tempDrills = [];
            selectedSession.forEach((item) => {
                tempDrills.push({
                    sessionId: item.id,
                    sessionName: item.sessionName,
                    isDrill: false,
                })
                item.drillLists.forEach((obj) => {
                    tempDrills.push({
                        sessionId: item.id,
                        sessionName: item.sessionName,
                        drillName: obj,
                        isDrill: true,
                        isChecked: true,
                        isSession: true
                    })
                })
            })
            handleSaveDrill(tempDrills)
            setDrills(tempDrills)
        }
    }, [selectedSession])
    const handleSelectedDrill = (id) => {
        let temp = [...drills];
        temp[id].isChecked = !temp[id].isChecked;
        setDrills(temp);
    }
    return (
        <Drawer
            anchor={'right'}
            open={openDrillSelectionBox}
            onClose={() => handleDrillSelectionClose(drills)}
        >
            <Box className="drill_selection" >
                <p className="drill_selection_heading">DRILLS</p>
                <Divider />
                <List className="drill_selection_body">
                    {drills.map((item,index) => (
                        <div key={index}>
                            <ListItem >
                                <Checkbox  sx={{
                                    color: "white",
                                    '&.Mui-checked': {
                                        color: "white",
                                        backgroundColor: "#2B3040"
                                    },
                                }}
                                    size="small"
                                    checked={item.isChecked}
                                    onClick={(e) => { handleSelectedDrill(index) }}
                                />
                                {item.isDrill ? item.drillName : item.sessionName}
                            </ListItem>
                            {!item.isDrill ? <Divider /> : ""}
                        </div>
                    ))}
                </List>
            </Box>
        </Drawer>
    )
}