import React, { useEffect, useState } from "react";
import { PieChart, Pie, Legend, Cell, ResponsiveContainer, Tooltip } from 'recharts';
export const Picharts = ({ selectedPiChartData, selectedPiMetric }) => {
  const [data, SetData] = useState([]);
  const COLORS = [
    "#8a00ff", "#2ecc71", "#a6cd1b",
    "#3498db", "#ff3377", "	#845422",
    "#95a5a6", "#255e5e", "	#77aaff",
    "#9b59b6", "#f88b89", "#273609",
    "#f1c40f", "#f03d00", "#ade5e5",
    "#e74c3c", "#7bff37", "#e9314c",
    "#34495e", "#a00600", "#5a4565",
    "#ff63fc", "#0c8059", "	#00ffc6",
    "#2a00ff", "#000000", "#ff00ca",
    "#618a61", "#ffbbee", "	#ffffff"
  ];
  useEffect(() => {
    if (selectedPiChartData && selectedPiMetric &&
      Array.isArray(selectedPiChartData) &&
      Array.isArray(selectedPiMetric)) {
      let val = selectedPiChartData.map((obj) => ({
        name: obj.name,
        value: Math.round(obj[selectedPiMetric[0]['id']])
      }))
      SetData(val)
    }
  }, [selectedPiMetric, selectedPiChartData])
  return (
    <div style={{ height: 300, width: "100%", minWidth: 260 }}>
      <ResponsiveContainer >
        <PieChart >
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            dataKey={"value"}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend
            iconSize={15}
            chartWidth={200} chartHeight={200}
            verticalAlign="middle" align="right" layout='vertical'
            wrapperStyle={{
              height: '80%',
              overflow: 'auto',
            }}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}