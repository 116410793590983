import { all, put, takeLatest } from "redux-saga/effects";
import { fetchUserDetails } from "../../services/userServices";
import { fetchUserProfileError, fetchUserProfileSuccess, userActionType } from "../actions/userAction";
// fetch user profile
function* _fetchUserData(action) {
    try {
        const userData = yield (fetchUserDetails(action.id));
        yield put(fetchUserProfileSuccess(userData))

    }
    catch (e) {
        yield put(fetchUserProfileError("error"))
    }
}
function* fetchUserData() {
    yield takeLatest(userActionType.FETCH_USER_PROFILE, _fetchUserData)
}
export function* UserSaga() {
    yield all([fetchUserData()])
}