export const periodLineChart = (lineChartData, selectedLineChartMetric) => {
    const temp = [];
    [...lineChartData].map((item) => {
        let data = { name: item.name, }
        selectedLineChartMetric.forEach((obj) => {
            data[obj['id']] = Math.round(item[obj['id']] / lineChartData.length)
        })
        return temp.push(data)
    })
    return temp
}
export const multipleSessionLineChart = (lineChartData, selectedLineChartMetric, noOfSession) => {
    const temp = [];
    [...lineChartData].map((item) => {
        let data = { name: item.name, }
        selectedLineChartMetric.forEach((obj) => {
            data[obj['id']] = Math.round(item[obj['id']] / noOfSession)
        })
        return temp.push(data)
    })
    return temp
}
export const LineCharts = (lineChartData, selectedLineChartMetric) => {
    const temp = [];
    [...lineChartData].map((item) => {
        let data = { name: item.name, }
        selectedLineChartMetric.forEach((obj) => {
            data[obj['id']] = Math.round(item[obj['id']])
        })
        return temp.push(data)
    })
    return temp
}
export const ProgressLineChart =(progressChartData,selectedLineChartMetric)=>{
    const temp = [];
    [...progressChartData].map((item) => {
        let data = { name: item.name, }
        selectedLineChartMetric.forEach((obj) => {
          data[obj['id']] = Math.round(item[obj['id']])
        })
        return temp.push(data)
      })
      return temp  
}