import { all, put, takeLatest } from "redux-saga/effects";
import { fetchMetricListError, fetchMetricListSuccess, metricActionType } from "../actions/metricsAction";
import { fetchMetricListData } from "../../services/metricServices";
function* _fetchMetrics(action) {
    try {
        const metrics = yield (fetchMetricListData(action.data))
        yield put(fetchMetricListSuccess(metrics))
    }
    catch (e) {
        yield put(fetchMetricListError("error"))
    }
}
function* fetchMetrics() {
    yield takeLatest(metricActionType.FETCH_METRIC_LIST, _fetchMetrics)
}
export function* MetricSaga() {
    yield all([fetchMetrics()])
}