import React from "react";
import { Dashboard } from "./components/dashboard/dashboard";
import store from "./redux/store";
import { Provider } from 'react-redux';
function App() {
  console.disableYellowBox = true;  
  return (
    <Provider store={store}>
      <div className="App">
        <Dashboard />
      </div>
    </Provider>
  );
}
export default App;
