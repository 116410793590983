import { metricActionType } from "../actions/metricsAction"

const dataTemplate = {
    isFetching: false,
    payload: [],
    error: false,
    errorMessage: ""
}
const initialData = {
    metricList: dataTemplate,
}
export const MetricReducer = (state = initialData, action) => {
    switch (action.type) {
        //add group
        case metricActionType.FETCH_METRIC_LIST:
            return {
                ...state,
                metricList: {
                    ...state.metricList,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case metricActionType.FETCH_METRIC_LIST_SUCCESS:
            return {
                ...state,
                metricList: {
                    ...state.metricList,
                    isFetching: false,
                    payload: action.payload,
                    error: false
                }
            }
        case metricActionType.FETCH_METRIC_LIST_ERROR:
            return {
                ...state,
                metricList: {
                    ...state.metricList,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        default:
            return state;
    }
}