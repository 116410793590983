import React, { useEffect, useState } from "react";
import { CardButton } from "../../styles/styles";
import SearchIcon from '@mui/icons-material/Search';
import {
    Card, CardActionArea, CardContent, CardHeader, Checkbox,
    Dialog, Grid, IconButton, InputAdornment, TextField
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import '../../styles/style.css'
export const PichartFirstDialog = ({ openPichartDialog, closePichartDialog, searchWord,
    searchInFristPiChart, tableGuageBox, handlePiChartMetrics, selectedPiMetric, handleSearchClear }) => {
    const [samplePiData, setSamplePiData] = useState([]);
    useEffect(() => {
        setSamplePiData(selectedPiMetric);
    }, [selectedPiMetric])
    const getChecked = (id) => {
        let found = [...samplePiData].find(item => item.id === id)
        return found || false;
    }
    const handlePiGroupData = (e, data) => {
        if (getChecked(data.id)) {
            const checked = samplePiData.filter((item) => item.id !== data.id)
            setSamplePiData(checked)
        }
        else {
            if (samplePiData.length >= 1) {
                alert("maximum 1 ")
            }
            else {
                let temp = [...samplePiData];
                temp.push(data)
                setSamplePiData(temp)
            }
        }
    }
    const handleSave = () => {
        if (samplePiData.length === 0) {
            alert("Please select atleast one data")
        }
        else { handlePiChartMetrics(samplePiData) }

        closePichartDialog();
        handleSearchClear();
    }
    const handleClose = () => {
        closePichartDialog();
        handleSearchClear();
    }
    return (
        <Dialog
            scroll={'body'}
            open={openPichartDialog}
            onClose={() => handleClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            minwidth="md"
        >
            <Grid container >
                <Grid item className="card-dialog">
                    <Card className="card-dialog">
                        <CardHeader
                            action={
                                <Grid container>
                                    <Grid item>
                                        <TextField sx={{ input: { color: 'white' } }}
                                            color="secondary"
                                            size="small"
                                            placeholder="Search"
                                            onChange={(e) => searchWord(e.target.value, 'search_in_first')}
                                            value={searchInFristPiChart}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon sx={{ color: "white" }} />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </Grid>
                                    <div className="space-card-header hidden-mobile"></div>
                                    <div className="space-btw-grid"></div>
                                    <Grid item >
                                        <IconButton onClick={() => handleClose()}>
                                            <CloseIcon sx={{ color: "white" }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            }
                        />
                                                   <div className='divider_daterangepick'></div>

                        <CardContent>
                            <Grid container justify="flex-end"
                                alignItems="center">
                                <Grid item lg={12} justify="flex-end"
                                    alignItems="center" >
                                    <div className="dialog_table_div">
                                        <table className="table Chart-table" style={{ color: "#fff" }} >
                                            <thead >
                                                <tr>
                                                    <th className="thborder">#</th>
                                                    <th className="thborder">Metric</th>
                                                    <th className="thborder">Show</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableGuageBox.map((row, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{row.label}</td>
                                                        <td><Checkbox sx={{
                                                            color: "white",
                                                            '&.Mui-checked': {
                                                                color: "white",
                                                                backgroundColor: "#2B3040"
                                                            },
                                                        }} size="small"
                                                            checked={getChecked(row.id)}

                                                            onChange={(e) => { handlePiGroupData(e, row) }} /></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActionArea>
                            <Grid container className="dialog_action_area">
                                <div className="space-card-button hidden-mobile hidden-tab"></div>
                                <Grid item>
                                    <CardButton variant="contained" className="dialog_button"
                                        onClick={handleSave}
                                    >Save</CardButton>
                                </Grid>
                            </Grid>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </Dialog>
    )
}