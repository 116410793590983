export const metricActionType = {

    FETCH_METRIC_LIST: "FETCH_METRIC_LIST",
    FETCH_METRIC_LIST_SUCCESS: "FETCH_METRIC_LIST_SUCCESS",
    FETCH_METRIC_LIST_ERROR: "FETCH_METRIC_LIST_ERROR",

}
export const fetchMetricList = () => {
    return {
        type: metricActionType.FETCH_METRIC_LIST,
    }
}
export const fetchMetricListSuccess = (data) => {
    return {
        type: metricActionType.FETCH_METRIC_LIST_SUCCESS,
        payload: data
    }
}
export const fetchMetricListError = (error) => {
    return {
        type: metricActionType.FETCH_METRIC_LIST_ERROR,
        error
    }
}
