import { all, put, takeLatest } from "redux-saga/effects"
import {
    addNewGroupDetails, fetchGroups, fetchSingleGroupDetails,
    removeGroupDetails, updateGroup
} from "../../services/groupServices"
import {
    addNewGroupError, addNewGroupSuccess, deleteGroupError,
    deleteGroupSuccess, fetchGroupListError, fetchGroupListSuccess,
    fetchSingleGroupError, fetchSingleGroupSuccess, groupActionType,
    UpdateGroupError, updateGroupSuccess
} from "../actions/groupAction"
//add group
function* _addGroup(action) {
    try {
        const newGroup = yield (addNewGroupDetails(action.data))
        yield put(addNewGroupSuccess(newGroup))
    } catch (e) {
        yield put(addNewGroupError("error"))
    }
}
function* addGroup() {
    yield takeLatest(groupActionType.ADD_NEW_GROUP, _addGroup)
}
//fetch group list
function* _fetchGroup(action) {
    try {
        const groupList = yield (fetchGroups(action.data))
        yield put(fetchGroupListSuccess(groupList))
    }
    catch (e) {
        yield put(fetchGroupListError("error"))
    }
}
function* fetchGroup() {
    yield takeLatest(groupActionType.FETCH_GROUP_LIST, _fetchGroup)
}
//fetch single group 
function* _fetchSingleGroup(action) {
    try {
        const singleGroup = yield (fetchSingleGroupDetails(action.id))
        yield put(fetchSingleGroupSuccess(singleGroup))
    } catch (e) {
        yield put(fetchSingleGroupError("error"))
    }
}
function* fetchSingleGroup() {
    yield takeLatest(groupActionType.FETCH_SINGLE_GROUP, _fetchSingleGroup)
}
//update group
function* _editGroup(action) {
    try {
        const editGroup = yield (updateGroup(action.id, action.data));
        yield put(updateGroupSuccess(editGroup))
    }
    catch (e) {
        yield put(UpdateGroupError("error"))
    }
}
function* editGroup() {
    yield takeLatest(groupActionType.UPDATE_GROUP, _editGroup)
}
//remove group
function* _removeGroup(action) {
    try {
        const removeGroupdata = yield (removeGroupDetails(action.id));
        yield put(deleteGroupSuccess(removeGroupdata))
    }
    catch (e) {
        yield put(deleteGroupError("error"))
    }
}
function* removeGroup() {
    yield takeLatest(groupActionType.DELETE_GROUP, _removeGroup)
}

export function* GroupSaga() {
    yield all([addGroup(), fetchGroup(), fetchSingleGroup(), editGroup(), removeGroup()])
}