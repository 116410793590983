import React, { useState, useEffect } from "react";
import {
    Card, CardActionArea, CardContent, CardHeader, Checkbox, Dialog,
    FormControl, Grid, IconButton, InputAdornment, TextField, InputLabel
} from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import '../../styles/style.css'
import { CardButton } from "../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import { addNewGroup, deleteGroup, fetchGroupList, fetchSingleGroup, updateGroup } from "../../redux/actions/groupAction";
export const LineChartDialog = ({ openDialogLinechart, openLineChartDialogClose,
    tableGuageBox, searchWord, search, handleSearchClear, selectedLineChartMetric,
    handleLineChart
}) => {
    const dispatch = useDispatch();
    const GroupListData = useSelector(state => state.group.groupData)
    const SingleGroupData = useSelector(state => state.group.singleGroup);
    const fetchAddeddata = useSelector(state => state.group.addGroup);
    const fetchDeletedata = useSelector(state => state.group.deleteGroup);
    const editedData = useSelector(state => state.group.updateGroup);
    const [groupList, setGroupList] = useState([])
    const [singleGroupDetails, setSingleGroupDetails] = useState({});
    const [displayValue, setDisplayValue] = useState(true);
    const [sampleLineChart, setSampleLineChart] = useState(selectedLineChartMetric)
    const [groupSaveDialog, setGroupSaveDialog] = useState(false)
    const [groupName, setGroupName] = useState('')
    useEffect(() => {
        dispatch(fetchGroupList());
    }, [])
    useEffect(() => {
        if (!GroupListData.isFetching && GroupListData.payload &&
            typeof GroupListData.payload.data !== "undefined") {
            setGroupList([...GroupListData.payload.data.group_list]);
        }
    }, [GroupListData]);
    useEffect(() => {
        if (!fetchAddeddata.isFetching && !fetchAddeddata.error && fetchAddeddata.payload.data) {
            setGroupSaveDialog(false)
            dispatch(fetchGroupList());
            setDisplayValue(false)
            handleClear()
        }
    }, [fetchAddeddata])
    useEffect(() => {
        if (!SingleGroupData.isFetching && SingleGroupData.payload &&
            typeof SingleGroupData.payload.data !== "undefined") {
            setSingleGroupDetails({ ...SingleGroupData.payload.data.group_details });
            let singleGr = { ...SingleGroupData.payload.data.group_details }
            let checkData = [...tableGuageBox].filter((item) => {
                return singleGr[item.id] || false;

            })
            setSampleLineChart(checkData)
            setDisplayValue(true)
            if (SingleGroupData.payload.data.group_details && SingleGroupData.payload.data.group_details.name)
                setGroupName(SingleGroupData.payload.data.group_details.name);
        }
    }, [SingleGroupData])
    useEffect(() => {
        if (!editedData.isFetching && !editedData.error && typeof editedData.payload.data != 'undefined') {
            setGroupSaveDialog(false)
            setDisplayValue(false)
            dispatch(fetchGroupList());
            handleClear()
            setSampleLineChart(selectedLineChartMetric)
            setSingleGroupDetails({})
        }
    }, [editedData])
    useEffect(() => {
        if (!fetchDeletedata.isFetching && !fetchDeletedata.error &&
            typeof fetchDeletedata.payload.success != 'undefined') {
            dispatch(fetchGroupList());
            alert("successfully deleted")
            setDisplayValue(false)
            setSampleLineChart(selectedLineChartMetric)
        }
    }, [fetchDeletedata])
    const getChecked = (item) => {
        const found = [...sampleLineChart].find((obj) => obj.id === item.id)
        return found || false
    }
    const handleGroupData = (e, element) => {
        let chk = getChecked(element);
        if (chk) {
            let checked = [...sampleLineChart].filter((item) => item.id !== element.id)
            setSampleLineChart(checked)
        }
        else {
            if (sampleLineChart.length > 4) {
                alert("Maximum select limit is 5")
            }
            else {
                let newElement = [...sampleLineChart];
                newElement.push(element)
                setSampleLineChart(newElement)
            }
        }
    }
    const handleSaveLineChartMetrics = () => {
        handleLineChart(sampleLineChart);
        openLineChartDialogClose();
    }
    const handleSaveGroupName = () => {
        let result = {
            "name": groupName,
            "user_id": 5,
        };
        [...sampleLineChart].forEach((item) => {
            result[item.id] = true;
        })
        if (singleGroupDetails.id) {
            dispatch(updateGroup(singleGroupDetails.id, result))
        }
        else {
            dispatch(addNewGroup(result));
        }
        setDisplayValue(false)
    }
    const handleEditGroup = (id) => {
        dispatch(fetchSingleGroup(id))
    }
    const handleDeleteGroup = (id) => {
        if (window.confirm("Are you sure you want to delete?")) {

            dispatch(deleteGroup(id))
        }
        else
            setTimeout(dispatch(fetchGroupList()),);
    }
    const handleClear = () => {
        setGroupName("")
    }
    const handleClose = () => {
        openLineChartDialogClose();
        handleSearchClear();
    }
    const handleDisplayValue = () => {
        setDisplayValue(false);
        setGroupName("")
    }
    const handleGroupMetric = (e, id) => {
        dispatch(fetchSingleGroup(id))
    }
    return (
        <Dialog
            scroll={'body'}
            open={openDialogLinechart}
            onClose={() => { handleClose() }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            minWidth="md"
        >
            <Grid container >
                <Grid item className="card-dialog">
                    <Card className="card-dialog">
                        {displayValue ?
                            <CardHeader className='groupheader'
                                action={
                                    <Grid container>
                                        <Grid item>
                                            <TextField sx={{ input: { color: 'white' } }}
                                                onChange={(e) => searchWord(e.target.value, 'search')}
                                                value={search}
                                                color="secondary"
                                                size="small"
                                                placeholder="Search"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon sx={{ color: "white" }} />
                                                        </InputAdornment>
                                                    ),
                                                }} />
                                        </Grid>
                                        <div className="space-btw-grid"></div>
                                        <div className="space-card-header hidden-mobile"></div>
                                        <Grid item >
                                            <IconButton
                                                onClick={() => handleClose()}
                                            >
                                                <CloseIcon sx={{ color: "white" }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                }
                            /> : null}
                        <CardContent>
                            <Grid container justify="flex-end" alignItems="center" className="whiteborderbtn">
                                <div className="divmarginbtm">
                                    <ul className="nav nav-button mb-3" id="pills-tab" role="tablist">
                                        <li className="nav-item  limarginbtm" role="presentation">
                                            <div className={displayValue ? "nav-button-active" : "nav-button"} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
                                                role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => { setDisplayValue(true) }}>Value</div>
                                        </li>
                                        <div className="nav-button-active"></div>
                                        <div className="space-datepicker"></div>
                                        <li className="nav-item limarginbtm" role="presentation">
                                            <div className={!displayValue ? "nav-button-active" : "nav-button"} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
                                                role="tab" aria-controls="pills-profile" aria-selected="true" onClick={() => { handleDisplayValue() }}>Group</div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="space-linechart-card-button hidden-mobile"></div>
                                {!displayValue ?
                                    <Grid item sx={{ ml: 20 }} >
                                        <IconButton
                                            onClick={() => { handleClose() }}
                                        >
                                            <CloseIcon sx={{ color: "white" }} />
                                        </IconButton>
                                    </Grid> : null}
                            </Grid>
                            <Grid container justify="flex-end"
                                alignItems="center">
                                {displayValue !== false ?
                                    <Grid item lg={12} justify="flex-end"
                                        alignItems="center" >
                                        <div className="dialog_table_div">
                                            {GroupListData.isFetching ?
                                                "Loading..." :
                                                <table className="table Chart-table"  >
                                                    <thead  >
                                                        <tr>
                                                            <th className="thborder">#</th>
                                                            <th className="thborder">Metricx</th>
                                                            <th className="thborder">Show</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tableGuageBox.map((row, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{row.label}</td>
                                                                <td><Checkbox sx={{
                                                                    color: "white",
                                                                    '&.Mui-checked': {
                                                                        color: "white",
                                                                        backgroundColor: "#2B3040"
                                                                    },
                                                                }} size="small"
                                                                    checked={getChecked(row)}
                                                                    //checked={row.value}
                                                                    onChange={(e) => { handleGroupData(e, row) }}
                                                                /></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>}
                                        </div>
                                    </Grid> : <Grid item lg={12} justify="flex-end"
                                        alignItems="center" >
                                        <div dialog_table_div>
                                            <table className="table Chart-table" >
                                                <thead  >
                                                    <tr>
                                                        <th className="thborder">#</th>
                                                        <th className="thborder">Group Name</th>
                                                        <th className="thborder">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {groupList.map((row, index) => (
                                                        <tr key={index}
                                                            onClick={(e) => { handleGroupMetric(e, row.id) }}
                                                        >
                                                            <td>{index + 1}</td>
                                                            <td>{row.name}</td>
                                                            <td>
                                                                <IconButton
                                                                    onClick={() => { handleEditGroup(row.id) }}
                                                                >
                                                                    <EditOutlinedIcon color="primary" fontSize="small" />
                                                                </IconButton>
                                                                <IconButton
                                                                    onClick={(e) => { e.stopPropagation(); handleDeleteGroup(row.id) }}
                                                                >
                                                                    < DeleteOutlineOutlinedIcon color='error' fontSize="small" />
                                                                </IconButton>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Grid>
                                }
                            </Grid>
                        </CardContent>
                        <CardActionArea>
                            <Grid container className="dialog_action_area">
                                <div className="space-linechart-card-button hidden-mobile hidden-tab"></div>
                                <Grid item>
                                    {displayValue ?
                                        <CardButton
                                            onClick={() => { setGroupSaveDialog(true) }}
                                            variant="contained" className="lineChart_dialog_create" >
                                            {singleGroupDetails.id ? "Update" : "Create"}
                                            Group
                                        </CardButton>
                                        : <div className="space-select-checkbox"></div>}
                                </Grid>
                                <Dialog
                                    scroll={'body'}
                                    open={groupSaveDialog}
                                    onClose={() => { setGroupSaveDialog(false) }}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    fullWidth
                                    maxWidth="xs"
                                >
                                    <Grid container  >
                                        <Grid item className="card-dialog-inner">
                                            <Card className="card-dialog-inner">
                                                <CardContent>
                                                    <Grid container spacing={0}
                                                    className="card_name_dialog" 
                                                        direction="column"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                    >
                                                        <Grid item className="match_box_divider">
                                                            <InputLabel className="userProfile">Group Name</InputLabel>
                                                            <FormControl className="match_box_divider">
                                                                <TextField className="card_name_textfield"
                                                                    size="small" color="secondary" fullWidth
                                                                    value={groupName}
                                                                    onChange={(e) => setGroupName(e.target.value)}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item className="dashboard_time">
                                                            <Grid container>
                                                                <div className="space-datepicker hidden-mobile hidden-tab "></div>
                                                                <Grid item >
                                                                    <CardButton variant="contained" sx={{ ml: 0 }}
                                                                        onClick={handleSaveGroupName}
                                                                    >Save</CardButton>

                                                                </Grid>
                                                                <Grid item>
                                                                    <CardButton variant="contained" className="lineChart_dialog_save"
                                                                        onClick={() => { setGroupSaveDialog(false) }}
                                                                    >Cancel
                                                                    </CardButton>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Dialog>
                                {displayValue !== false ?
                                    <Grid item>
                                        <CardButton
                                            className="lineChart_dialog_save"
                                            variant="contained"
                                            onClick={() => handleSaveLineChartMetrics()}
                                        >Save
                                        </CardButton>
                                    </Grid> : null}
                            </Grid>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </Dialog>
    )
}