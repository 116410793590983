import React, { useState, useEffect } from "react";
import '../../styles/style.css'
import '../../images/halofit-logo.svg'
import {
    AppBar, Grid, IconButton, List, ListItem, Paper, SwipeableDrawer, Toolbar
} from "@mui/material";
import { Box } from "@mui/system";
import { faBars } from '@fortawesome/fontawesome-free-solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PlayerButton } from "../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile } from "../../redux/actions/userAction";
import { DateRangePick } from "./daterangePicker";
import { fetchPlayerList } from "../../redux/actions/playerAction";
import { PlayerListDialog } from "../dialogbox/playerListDialog";
import { fetchSession } from "../../redux/actions/dashboardAction";
export const Header = ({ handleSelectedSession, handleselectedPlayers }) => {
    const dispatch = useDispatch();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [userProfile, setUserProfile] = useState({});
    const [playersData, setPlayersData] = useState([]);
    const [playerCount, setPlayerCount] = useState(0);
    const [openPlayerDialog, setOpenPlayerDialog] = useState(false);
    const userData = useSelector(state => state.user.userProfile);
    const [selectedSession, setSelectedSession] = useState([])
    useEffect(() => {
        const id = 5;
        dispatch(fetchUserProfile(id))
        let listId = 52;
        dispatch(fetchPlayerList(listId));
    }
        , [])

    useEffect(() => {
        if (!userData.isFetching && userData.payload && typeof userData.payload.data !== "undefined") {
            setUserProfile(userData.payload.data.user_details);
        }
    }
        , [userData])
    // useEffect(() => {
    //     if (!playersList.isFetching && playersList.payload && typeof playersList.payload.data !== "undefined") {
    //         let temp = selectedPlayer;
    //         const count = selectedPlayer.filter((item) => item.isSelected).length;
    //         setPlayerCount(count)
    //         setPlayersData(temp);
    //     }
    // }
    //     , [playersList, selectedPlayer])
    useEffect(() => {
        if (selectedSession) {
            const playerListData = [];
            selectedSession.forEach((item) => {
                if (playerListData.indexOf(item.name) === -1)
                    playerListData.push(item)
            }
            )
            setPlayersData(playerListData)
            const count = playerListData.filter((item) => item.isSelected).length;
            setPlayerCount(count)

        }
    }, [selectedSession])
    const openDialogClose = () => {
        setOpenPlayerDialog(false);
    }
    const handlePlayer = (data) => {
        setSelectedSession(data)
    }
    return (
        <div>
            <Box className="dashboard_box">
                {/* <DataSwitcher /> */}
                <AppBar className="header" position="static" >
                    <Toolbar>
                        <Grid container >
                            <Grid item>
                                <IconButton onClick={() => setOpenDrawer(!openDrawer)}
                                    disableRipple >
                                    <FontAwesomeIcon icon={faBars} size="lg" inverse />
                                </IconButton>
                                {openDrawer ? <SwipeableDrawer
                                    open={openDrawer}
                                    onClose={() => setOpenDrawer(false)}
                                    onOpen={() => setOpenDrawer(true)}
                                >
                                    <Box />
                                    <Paper className="Drawer-paper" >
                                        <List >
                                            <ListItem >
                                                <Grid container>
                                                    <Grid item>
                                                        <IconButton onClick={() => setOpenDrawer(!openDrawer)}
                                                            disableRipple >
                                                            <FontAwesomeIcon icon={faBars} size="lg" inverse />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                        </List>
                                    </Paper>
                                </SwipeableDrawer> : null}
                                <img className="logo img-fluid" src={require("../../images/halofit-logo.svg")} alt='mySvgImage' />
                            </Grid>
                            <Grid item>
                                <PlayerButton
                                    onClick={() => setOpenPlayerDialog(!openPlayerDialog)}
                                >
                                    {playerCount} Players
                                </PlayerButton>
                                <PlayerListDialog openDialogClose={openDialogClose}
                                    openPlayerDialog={openPlayerDialog}
                                    playersData={playersData}
                                    handlePlayer={handlePlayer}
                                    // selectedPlayer={selectedPlayer}
                                    handleselectedPlayers={handleselectedPlayers}
                                />
                            </Grid>
                            <div className="space hidden-mobile "></div>
                            {/* datepicker */}
                            <Grid item sm={12} className="date-picker" lg={'auto'}>
                                <DateRangePick
                                    handleDateChangefn={(data) => {
                                        dispatch(fetchSession(data))
                                    }}
                                    handleSelectedSession={handleSelectedSession}
                                    handlePlayer={handlePlayer}
                                    handleselectedPlayers={handleselectedPlayers}
                                />
                            </Grid>
                            <div className="space-profile hidden-mobile "></div>
                            <Grid item className="userProfile_box" lg={'auto'} >
                                <Grid className="userProfile_box hidden-mobile hidden-tab" container >
                                    <Grid item className="userProfile_box">
                                        <img src={require("../../images/profile.png")} alt="profilepic" className="img-fluid " />
                                    </Grid>
                                    <Grid item className="userProfile_content">
                                        <h6 className="userProfile">{userProfile.name}</h6>
                                        <p className="admin">Admin</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </Box>
        </div>
    )
}