import { API } from './api';
//add group
export const addNewGroupDetails = async (body) => {
    const response = await API.post("groups", body
    )
    return response.data
}
//fet group list
export const fetchGroups = async () => {

    const response = await API.get("groups"
    )
    return response.data;
}
//fetch single group
export const fetchSingleGroupDetails = async (id) => {
    const response = await API.get("groups/" + id)
    return response.data;
}
//update group
export const updateGroup = async (id, body) => {
    const response = await API.put("groups/" + id, body)
    return response.data;

}
//delete group
export const removeGroupDetails = async (id) => {
    const response = await API.delete("groups/" + id)
    return response.data;
}