import React, { useEffect, useState } from "react";
import { CardButton } from "../../styles/styles";
import SearchIcon from '@mui/icons-material/Search';
import {
    Card, CardActionArea, CardContent, CardHeader, Checkbox,
    Dialog, Grid, IconButton, InputAdornment, TextField
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import '../../styles/style.css'
export const GaugeboxDialog = ({ openGuageboxDialog, tableGuageBox, searchWord,
    openDialog, search, selectedGauges, handleGuageBox, handleSearchClear }) => {
    const [sampleGuage, setSampleGuage] = useState(selectedGauges)
    useEffect(() => {
        setSampleGuage(selectedGauges)
    }, [selectedGauges])
    const handleGuageboxData = (e, data) => {
        if (getChecked(data.id)) {
            const checked = sampleGuage.filter((item) => {
                return item.id !== data.id
            })
            setSampleGuage(checked)
        }
        else {
            if (sampleGuage.length >= 5) {
                alert("maximum  selection limit is 5")
            }
            else {
                let temp = [...sampleGuage];
                temp.push(data)
                setSampleGuage(temp)
            }
        }
    }
    const getChecked = (id) => {
        const found = [...sampleGuage].find(item => item.id === id);
        return found || false;
    }
    const handleSave = () => {
        if (sampleGuage.length === 0) {
            alert("Please select atleast one metric")
        }
        else {
            handleGuageBox(sampleGuage)
        }
        openGuageboxDialog()
        handleSearchClear()
    }
    const handleClose = () => {
        openGuageboxDialog();
        handleSearchClear()
    }
    return (
        <div>
            <Dialog
                open={openDialog}
                scroll={'body'}
                onClose={() => handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                minWidth="md"
            >
                <Grid container >
                    <Grid item className="card-dialog">
                        <Card className="card-dialog">
                            <CardHeader
                                action={
                                    <Grid container>
                                        <Grid item>
                                            <TextField
                                                sx={{ input: { color: 'white' } }}
                                                onChange={(e) => searchWord(e.target.value, 'search')}
                                                value={search}
                                                color="secondary"
                                                size="small"
                                                placeholder="Search"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon sx={{ color: "white" }} />
                                                        </InputAdornment>
                                                    ),
                                                }} />
                                        </Grid>
                                        <div className="space-btw-grid"></div>
                                        <div className="space-card-header hidden-mobile"></div>
                                        <Grid item >
                                            <IconButton onClick={() => handleClose()}>
                                                <CloseIcon sx={{ color: "white" }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                }
                            />
                            <div className='divider_daterangepick'></div>
                            <CardContent>
                                <Grid container justify="flex-end" alignItems="center">
                                    <Grid item lg={12} justify="flex-end" alignItems="center" >
                                        <div className="dialog_table_div">
                                            <table className="table Chart-table"  >
                                                <thead >
                                                    <tr>
                                                        <th className="thborder">#</th>
                                                        <th className="thborder">Metric</th>
                                                        <th className="thborder">Show</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tableGuageBox.map((row, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{row.label}</td>
                                                            <td>
                                                                <Checkbox sx={{
                                                                    color: "white",
                                                                    '&.Mui-checked': {
                                                                        color: "white",
                                                                        backgroundColor: "#2B3040"
                                                                    },
                                                                }} size="small"
                                                                    checked={getChecked(row.id)}
                                                                    onChange={(e) => { handleGuageboxData(e, row) }}

                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActionArea>
                                <Grid container className="dialog_action_area">
                                    <div className="space-card-button hidden-mobile hidden-tab"></div>
                                    <Grid item>
                                        <CardButton variant="contained" className="dialog_button"
                                            onClick={handleSave}
                                        >Save</CardButton>
                                    </Grid>
                                </Grid>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
            </Dialog>
        </div>
    )
}