import { userActionType } from "../actions/userAction"

const dataTemplate = {
    isFetching: false,
    payload: [],
    error: false,
    errorMessage: ""
}
const initialData = {
    userProfile: dataTemplate,
}
export const UserReducer = (state = initialData, action) => {
    switch (action.type) {
        //fetch groups
        case userActionType.FETCH_USER_PROFILE:
            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case userActionType.FETCH_USER_PROFILE_SUCCESS:
            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    isFetching: false,
                    payload: action.payload,
                    error: false
                }
            }
        case userActionType.FETCH_USER_PROFILE_ERROR:
            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        default:
            return state;
    }
}