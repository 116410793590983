/* eslint-disable */ 
import React, { useState, useEffect } from "react";
import { Checkbox, FormControl, FormControlLabel, Grid, IconButton, ListItemText, 
    MenuItem, Paper, Select } from "@mui/material";
import '../../styles/style.css'
import "../../images/info-btn.png";
import { DateRangePick } from "../sharable/daterangePicker";
import { useDispatch } from "react-redux";
import { fetchSessionPi } from "../../redux/actions/dashboardAction";
import { filterdPiChartData, getPichartData } from "../../helpers/dashboardHelper";
import { MenuProps } from "../../styles/styles";
import { PichartFirstDialog } from "../dialogbox/pichartFirstDialog";
import { Picharts } from "../sharable/picharts";
export const DashboardPiChart = ({ searchWord, searchInFristPiChart, tableGuageBox, selectedPiMetric,
    handleSearchClear, handlePiChartMetrics, selectedSecondPiMetric, handleSecondPiChartMetrics, selectedSecondPiData,
    searchInSecondPiChart }) => {
    const dispatch = useDispatch();
    // pichart1 dialog
    const [openPichartDialog, setOpenPichartDialog] = useState(false);
    // pichart2 dialog
    const [openPichartDialogSecond, setOpenPichartDialogSecond] = useState(false);
    const [selectedPiSession, SetSelectedPiSession] = useState([])
    const [piPlayerData, setPiPlayerData] = useState([])
    const [selectedMatchName, setSelectedMatchName] = useState("")
    const [selectedPiZoneData, setSelectedPiZoneData] = useState([])
    const [selectedPiChartData, setSelectedPiChartData] = useState([])
    const [zone, setZone] = useState([]);
    //Pichart
    useEffect(() => {
        if (Array.isArray(selectedPiSession) && selectedPiSession.length) {
            let tempMatchName = selectedPiSession[0].sessionName;
            setSelectedMatchName(tempMatchName);
            [...selectedPiSession].map((data) => {
                return data.sessionName
            })
            const tempZoneData = [];
            [...selectedPiSession].forEach((item) => {
                if (item.sessionName === tempMatchName) {
                    tempZoneData.push(item.drillLists)
                }
            })
            let zoneData = tempZoneData.flat();
            setSelectedPiZoneData(zoneData)
            setZone(zoneData)
            const tempPiData = getPichartData([...selectedPiSession], [...piPlayerData]);
            let samplePiChartData = Object.keys(tempPiData).map((item) => tempPiData[item])
            setSelectedPiChartData(samplePiChartData)
        }
    }, [selectedPiSession])
    const closePichartDialog = () => {
        setOpenPichartDialog(false)
    }
    const closePichartDialogSecond = () => {
        setOpenPichartDialogSecond(false)
    }
    const handlePiData = (data, player) => {
        SetSelectedPiSession(data);
        setPiPlayerData(player)
    }
    const handlePiZoneData = (value) => {
        let temp = value;
        setZone(typeof value === 'string' ? value.split(',') : value,);
        if (Array.isArray(selectedPiSession) && selectedPiSession.length) {
            const tempPiData = filterdPiChartData([...selectedPiSession], temp, [...piPlayerData]);
            let samplePiChartData = Object.keys(tempPiData).map((item) => tempPiData[item])
            setSelectedPiChartData(samplePiChartData)
        }
    }
    return (
        <Grid container className=" container merit-bg" >
            <Grid container justify="flex-end" alignItems="center" className="divider">
                <div className="space-pichart-datepicker  hidden-mobile hidden-tab"></div>
                <Grid item sm={12} className="date-picker" lg={'auto'} style={{ height: 55 }}>
                    <DateRangePick
                        handleDateChangefn={(data) => {
                            dispatch(fetchSessionPi(data))
                        }}
                        piKey={"pidateRange"}
                        handlePiData={handlePiData}
                    />
                </Grid>
                <div className="space-filter hidden-mobile hidden-tab"></div>
                <Grid item  sm={12} lg={3}>
                    <h6 className="userProfile">{selectedMatchName}</h6>
                </Grid>
                <div className="space-select-checkbox hidden-mobile hidden-tab"></div>
                <Grid item  >
                    <FormControl sx={{ minWidth: 150 }}>
                        <FormControlLabel className="select"
                            sx={{ color: "white", fontFamily: 'Lato sans-serif' }}
                            control={
                                <Select
                                    className="userProfile select-player-wrap"
                                    sx={{
                                        color: "white", ml: 1, minWidth: 100,
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(228, 219, 233, 0.25)',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(228, 219, 233, 0.25)',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(228, 219, 233, 0.25)',
                                        },
                                        '.MuiSvgIcon-root ': {
                                            fill: "white !important",
                                        }
                                    }}
                                    multiple
                                    value={zone}
                                    onChange={(e) => handlePiZoneData(e.target.value)}
                                    MenuProps={MenuProps}
                                    renderValue={(selected) => selected.join(', ')}
                                >
                                    {selectedPiZoneData.map((name, index) => (


                                        <MenuItem key={index} value={name}>
                                            <Checkbox checked={zone.indexOf(name) > -1} />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>}
                            label="Zone"
                            labelPlacement="start"
                        />
                    </FormControl>
                </Grid>
                <div className="space-for-divider"></div>
            </Grid>
            <Grid container>
                <Grid item lg={5} sm={12} className="pichart-bg pichart_width reduce-pichart"
                    justify="space-around">
                    <Grid container>
                        <div className="space-info-icon-pichart"></div>
                        <Grid item sx={{ pt: 1 }} >
                            <IconButton onClick={() => setOpenPichartDialog(!openPichartDialog)}>
                                <img src={require("../../images/info-btn.png")} alt="info button" />
                            </IconButton>
                        </Grid>
                        <PichartFirstDialog openPichartDialog={openPichartDialog}
                            closePichartDialog={closePichartDialog}
                            searchWord={searchWord}
                            searchInFristPiChart={searchInFristPiChart} tableGuageBox={tableGuageBox}
                            selectedPiMetric={selectedPiMetric}
                            handlePiChartMetrics={handlePiChartMetrics}
                            handleSearchClear={handleSearchClear}
                        />
                    </Grid>
                    <Paper className="pichart-bg" elevation={0} >

                        {selectedPiMetric.map((item,index) => (
                            <h6 key={index}>{item.label}</h6>
                        ))}
                        <Picharts
                            selectedPiChartData={selectedPiChartData}
                            selectedPiMetric={selectedPiMetric}
                        />
                    </Paper>
                </Grid>
                <div className="space-pichart "></div>
                <Grid item lg={5} sm={12} className="pichart-bg pichart_width reduce-pichart" justify="space-around">
                    <Grid container>
                        <div className="space-info-icon-pichart"></div>
                        <Grid item sx={{ pt: 1 }}>
                            <IconButton onClick={() => setOpenPichartDialogSecond(!openPichartDialogSecond)}>
                                <img src={require("../../images/info-btn.png")} alt="info button" />
                            </IconButton>
                        </Grid>
                        <PichartFirstDialog
                            handlePiChartMetrics={handleSecondPiChartMetrics}
                            openPichartDialog={openPichartDialogSecond}
                            closePichartDialog={closePichartDialogSecond}
                            searchWord={searchWord}
                            searchInFristPiChart={searchInSecondPiChart}
                            tableGuageBox={tableGuageBox}
                            selectedPiMetric={selectedSecondPiData}
                            handleSearchClear={handleSearchClear}
                        />
                    </Grid>
                    <Paper className="pichart-bg" elevation={0} >
                        {selectedSecondPiData.map((item,index) => (
                            <h6 key={index}>{item.label}</h6>
                        ))}
                        <Picharts
                            selectedPiChartData={selectedPiChartData}
                            selectedPiMetric={selectedSecondPiData}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    )
}