import { DashboardReducer } from "./dashboardReducer";
import { GroupReducer } from "./groupReducer";
import { MetricReducer } from "./metricReducer";
import { PlayerReducer } from "./playerReducer";
import { UserReducer } from "./userReducer";

const rootReducer = {
    group: GroupReducer,
    user: UserReducer,
    player: PlayerReducer,
    dashboard: DashboardReducer,
    metrics: MetricReducer,
}
export default rootReducer;