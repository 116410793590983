import React, { useEffect, useState } from "react";
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip,
  Legend, ResponsiveContainer
} from 'recharts';
import { ChartTooltip } from "./chartTooltip";
import { LineCharts, ProgressLineChart, multipleSessionLineChart, periodLineChart } from "./chartHelper";
export const Charts = ({ lineChartData, selectedLineChartMetric, progressChartData ,combo,sessionDropdown,
  noOfSession}) => {
  const [lineChart, setLineChart] = useState([]);
  const COLORS = ["#A622BB", "#6743B4", "#99cc00", "#2151B1", "#00e68a"];
  useEffect(() => {
    if (Array.isArray(lineChartData) && lineChartData.length) {
      if(sessionDropdown ===2 || sessionDropdown ===5){
      let sampleData = periodLineChart([...lineChartData],[...selectedLineChartMetric]);
      setLineChart(sampleData)
    }
    else if(sessionDropdown === 4){
      let sampleData = multipleSessionLineChart([...lineChartData],[...selectedLineChartMetric],noOfSession);
      setLineChart(sampleData)
    }
      else{
        let sampleData = LineCharts([...lineChartData],[...selectedLineChartMetric]);
        setLineChart(sampleData)
      }   
    }
    else {
      setLineChart([])
    }
    if (progressChartData) {
      let sampleData = ProgressLineChart([...progressChartData],[...selectedLineChartMetric]);
      setLineChart(sampleData)
    }
  }, [lineChartData, selectedLineChartMetric, progressChartData])
  const handleSortedData =(data) =>{
    setLineChart(data)
  }
  return (
   <div>
      <ChartTooltip 
     combo={combo}
      lineChartData={lineChartData}
      progressChartData={progressChartData}
      handleSortedData={handleSortedData}
      selectedLineChartMetric={selectedLineChartMetric}
      sessionDropdown={sessionDropdown}
      noOfSession={noOfSession}
      />
    <div className="chart_container">
      <ResponsiveContainer  className="chart">
        <LineChart data={lineChart}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid
            stroke="#474747" />
          <XAxis tickCount={25} dataKey="name" sx={{ color: "white" }} padding={{ left: 30, right: 30 }} />
          <YAxis tickCount={10} domain={[0, 'dataMax + 1000']} padding={{ top: 10, bottom: 10 }}
            tickFormatter={(number) => {
              if (number > 1000000000) {
                return (number / 1000000000).toFixed(3).toString() + 'B';
              } else if (number > 1000000) {
                return (number / 1000000).toFixed(3).toString() + 'M';
              } else if (number > 1000) {
                return (number / 1000).toFixed(3).toString() + 'K';
              } else {
                return number.toString();
              }
            }}
          />
          <Tooltip />
          <Legend />
          {
            selectedLineChartMetric && selectedLineChartMetric.map((item, index) => (
              <Line key={index} type="linear" dataKey={item.id} name={item.label}
                stroke={COLORS[index % COLORS.length]}
                legendType="rect" activeDot={{ r: 5 }} />
            ))
          }
        </LineChart>
      </ResponsiveContainer>
      </div>
   </div>
      
  )
}