export const getProgressZoneData=(selectedProgressSession,progressPlayerName)=>{
    const tempMatchName = selectedProgressSession.map((item) => (item.sessionName));
    const tempZoneData = [];
    [...selectedProgressSession].forEach((item) => {
        tempMatchName.forEach((data) => {
            if (item.sessionName === data) {
                item.sessionPlayers.forEach((obj)=>{
                    if(obj.playerDetails.firstName+obj.playerDetails.lastName===progressPlayerName)
                    tempZoneData.push(item.drillLists)
                })
                
            }
        })
    })
    const sampleDrillZone=tempZoneData.flat().reduce((acc,cur)=>{
        if(!acc.find((item)=>item===cur)){
            acc.push(cur)
        }
        return acc;
    },[])
    return sampleDrillZone;
}
export const getProgressChartData=(selectedSessions,progressPlayerName)=>{
    let tempdrills=[];
    [...selectedSessions].forEach((data) => {
        data.sessionPlayers.forEach((obj) => {
            (obj.drills).forEach((item) => {
                if (progressPlayerName === obj.playerDetails.firstName+obj.playerDetails.lastName) {

                    tempdrills.push({
                        name: item.drillName,
                        startTime:item.startTime,
                        ...item.drillKpi
                    })
                }

            })
        })
    })
    return tempdrills;
}
export const getProgressMatchData = (selectedProgressSession,progressPlayerName) =>{
    let tempSession = [];
    [...selectedProgressSession].forEach(((data) => {
        data.sessionPlayers.forEach((obj) => {
            (obj.drills).forEach((item) => {
                if (progressPlayerName === obj.playerDetails.firstName+obj.playerDetails.lastName) {
                    if (!tempSession[data.id]) {
                        tempSession[data.id] = {
                            id: data.id,
                            startTime:data.sessionDetails.startTime,
                            name: data.sessionName,
                            ...item.drillKpi
                        }
                    } else {
                        Object.keys(item.drillKpi).forEach((obj) => {
                            tempSession[data.id][obj] += item.drillKpi[obj];
                        })
                    }
                }
            })
        })
    }))
return tempSession;
}
export const getfilterdProgressChartData =(selectedProgressSession,temp,progressPlayerName)=>{
    let tempdrills=[];
    [...selectedProgressSession].forEach((data) => {
        data.sessionPlayers.forEach((obj) => {
            temp.forEach((ele) => {
                (obj.drills).forEach((item) => {
                    if (ele === item.drillName)
                        if (progressPlayerName === obj.playerDetails.firstName+obj.playerDetails.lastName) {

                            tempdrills.push({
                                name: item.drillName,
                                startTime:item.startTime,
                                ...item.drillKpi
                            })
                        }

                })
            })
        })
    })
    return tempdrills;
}
export const getfilterdProgressMatchData =(selectedProgressSession,temp,progressPlayerName)=>{
    let tempSession=[];
    [...selectedProgressSession].forEach(((data) => {
        data.sessionPlayers.forEach((obj) => {
            (obj.drills).forEach((item) => {
                temp.forEach((ele) => {
                    if (ele === item.drillName)
                if (progressPlayerName === obj.playerDetails.firstName+obj.playerDetails.lastName) {
                    if (!tempSession[data.id]) {
                        tempSession[data.id] = {
                            id: data.id,
                            name: data.sessionName,
                            startTime:data.sessionDetails.startTime,
                            ...item.drillKpi
                        }
                    } else {
                        Object.keys(item.drillKpi).forEach((obj) => {
                            tempSession[data.id][obj] += item.drillKpi[obj];
                        })
                    }
                }
            })
            })
        })
    }))
    return tempSession;
}
export const getPichartData =(selectedPiSession,piPlayerData)=>{
    let tempPiData=[];
    [...selectedPiSession].forEach((data) => {
        data.sessionPlayers.forEach((item) => {
            item.drills.forEach((ele) => {
                [...piPlayerData].forEach((x) => {
                    if (x.id === ele.sessionPlayerDataId && x.isSelected) {
                        if (!tempPiData[item.id]) {
                            tempPiData[item.id] = {
                                id: item.id,
                                name: item.playerDetails['firstName'] + " " + item.playerDetails['lastName'],
                                ...ele.drillKpi
                            }
                        }
                        else {
                            Object.keys(ele.drillKpi).forEach((obj) => {
                                tempPiData[item.id][obj] += ele.drillKpi[obj];
                            })
                        }
                    }
                })
            })
        })


    })
    return tempPiData;
}
export const filterdPiChartData=(selectedPiSession,temp,piPlayerData)=>{
    let tempPiData=[];
    [...selectedPiSession].forEach((data) => {
        data.sessionPlayers.forEach((item) => {
            item.drills.forEach((ele) => {
                temp.forEach((xyz)=>{
                    if(xyz===ele.drillName)
                [...piPlayerData].forEach((x) => {
                    if (x.id === ele.sessionPlayerDataId && x.isSelected) {
                        if (!tempPiData[item.id]) {
                            tempPiData[item.id] = {
                                id: item.id,
                                name: item.playerDetails['firstName'] + " " + item.playerDetails['lastName'],
                                ...ele.drillKpi
                            }
                        }
                        else {
                            Object.keys(ele.drillKpi).forEach((obj) => {
                                tempPiData[item.id][obj] += ele.drillKpi[obj];
                            })
                        }
                    }
                })
            })
            })
        })
    })
    return tempPiData
}