/* eslint-disable */ 
import React, { useEffect, useState, useRef } from "react";
import {
    Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, IconButton,
    ListItemText, MenuItem, Paper, Select
} from "@mui/material";
import { Charts } from "../sharable/charts";
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import '../../styles/style.css'
import "../../images/info-btn.png";
import format from 'date-fns/format'
import { subDays } from 'date-fns'
import { ProgresschartDialog } from "../dialogbox/progresschartDialog";
import { MenuProps } from "../../styles/styles";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DateRange } from "react-date-range";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { fetchSessionProgress } from "../../redux/actions/dashboardAction";
import {
    getProgressChartData, getProgressMatchData, getProgressZoneData, getfilterdProgressChartData,
    getfilterdProgressMatchData
} from "../../helpers/dashboardHelper";
export const DashboardProgressChart = ({ selectedProgressChartMetric,
    searchWord, tableGuageBox, searchInProgressChart, handleSearchClear, handleProgressChartMetrics }) => {
    const [openProgressDialog, setOpenProgressDialog] = useState(false);
    const [displayPlayer, setDisplayPlayer] = useState(true);
    const [selectedProgressSession, setSelectedProgressSession] = useState([]);
    const [progressChartPlayer, setProgressChartPlayer] = useState([]);
    const [progressPlayerName, setProgressPlayerName] = useState(' ');
    const [filterBy, setFilterBy] = useState('drill');
    const [selectedProgressZoneData, setSelectedProgressZoneData] = useState([]);
    const [progressChartData, setProgressChartData] = useState([]);
    const [progressZone, setProgressZone] = useState([]);
    const dispatch = useDispatch();
    const sessionData = useSelector(state => state.dashboard.progressSessionList);
    // open close
    const [open, setOpen] = useState(false);
    //   for calender progress chart
    const [chartRange, setChartRange] = useState([
        {
            startDate: subDays(new Date(), 5),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const refOne = useRef(null);
    // group edit 
    useEffect(() => {
        // event listeners
        document.addEventListener("keydown", hideOnEscape, true)
        document.addEventListener("click", hideOnClickOutside, !open)
    }, []);
    useEffect(() => {
        let startDate = chartRange[0].startDate;
        let endDate = chartRange[0].endDate;
        let data = {
            sessionStartDate: moment(startDate).utcOffset(0, true).format(),
            sessionEndDate: moment(endDate).utcOffset(0, true).format()
        }
        dispatch(fetchSessionProgress(data))
    }, [chartRange]);
    useEffect(() => {
        if (!sessionData.isFetching && !sessionData.error && sessionData.payload.data) {
            if (Array.isArray(sessionData.payload.data.session_data) &&
                sessionData.payload.data.session_data.length) {
                setSelectedProgressSession(sessionData.payload.data.session_data);
                const temp = sessionData.payload.data.session_data;
                let tempPlayers = [];
                temp.forEach((item) => {
                    item.sessionPlayers.forEach((item) => {
                        tempPlayers.push({
                            player: item.playerDetails,
                            id: item.id,
                            name: item.playerDetails.firstName + item.playerDetails.lastName,
                            isSelected: true
                        })
                    })

                });
                const filteredPlayers=tempPlayers.reduce((acc, cur) => {
                    if (!acc.find((item) => item.name === cur.name)) {
                        acc.push(cur)
                    }
                    return acc;
                }, [])
                setProgressChartPlayer(filteredPlayers);
                setOpen(false);
            }
        }
    }, [sessionData]);
    useEffect(() => {
        if (progressChartPlayer) {
            const temp = [...progressChartPlayer].filter((item) => item.isSelected);
            if (temp.length) {
                setProgressPlayerName(temp[0].name)
            }
        }
    }, [progressChartPlayer]);
    useEffect(() => {
        if (Array.isArray(selectedProgressSession) && selectedProgressSession.length) {
            const progressZoneData = getProgressZoneData([...selectedProgressSession], progressPlayerName);
            setSelectedProgressZoneData(progressZoneData);
            setProgressZone(progressZoneData);
            if (filterBy === 'drill') {
                const tempdrills = getProgressChartData([...selectedProgressSession], progressPlayerName);
                setProgressChartData(tempdrills);
            }
            else {
                const tempSession = getProgressMatchData([...selectedProgressSession], progressPlayerName);
                let ProgressDataList = Object.keys(tempSession).map((item) => tempSession[item]);
                setProgressChartData(ProgressDataList);
            }
        }
    }, [selectedProgressSession, progressPlayerName, filterBy])

    // hide dropdown on ESC press
    const hideOnEscape = (e) => {
        if (e.key === "Escape") {
            setOpen(false)
        }
    }
    // Hide on outside click
    const hideOnClickOutside = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setOpen(false)
        }
    }
    const openProgressDialogClose = () => {
        setOpenProgressDialog(false);
    }
    const handleProgressZoneData = (value) => {
        let temp = value;
        setProgressZone(typeof value === 'string' ? value.split(',') : value,)
        if (Array.isArray(selectedProgressSession) && selectedProgressSession.length) {
            if (filterBy === 'drill') {
                const tempdrills = getfilterdProgressChartData([...selectedProgressSession], temp, progressPlayerName);
                setProgressChartData(tempdrills)
            }
            else {
                const tempSession = getfilterdProgressMatchData([...selectedProgressSession], temp, progressPlayerName);
                let ProgressDataList = Object.keys(tempSession).map((item) => tempSession[item])
                setProgressChartData(ProgressDataList)
            }
        }
    }
    return (
        <>
            <Grid container className=" container merit-bg bg-chart"
                justify="flex-end" sx={{ backgroundColor: "202733" }}>
                <div className="space-info-icon"></div>
                <Grid container >
                    <div className="space-info-icon "></div>
                    <div className="space-select-player hidden-mobile "></div>
                    <Grid item sx={{ pb: 2 }}>
                        <IconButton onClick={() => setOpenProgressDialog(!openProgressDialog)}>
                            <img src={require("../../images/info-btn.png")} alt="info button" />
                        </IconButton>
                        <ProgresschartDialog
                            openProgressDialog={openProgressDialog}

                            openProgressDialogClose={openProgressDialogClose}
                            searchWord={searchWord}
                            tableGuageBox={tableGuageBox}
                            searchInProgressChart={searchInProgressChart}
                            handleSearchClear={handleSearchClear}
                            selectedProgressChartMetric={selectedProgressChartMetric}
                            handleProgressChartMetrics={handleProgressChartMetrics}
                        />
                    </Grid>
                </Grid>
                <div className="nav-header-div">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className={displayPlayer ? "active nav-link" : "nav-link"} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
                                type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => { setDisplayPlayer(true) }}>Player's</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={!displayPlayer ? "active nav-link" : "nav-link"} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
                                type="button" role="tab" aria-controls="pills-profile" aria-selected="true" onClick={() => { setDisplayPlayer(false) }}>Squad</button>
                        </li>
                    </ul>
                </div>
                <Grid container>
                    <div className="space-select-player"></div>
                    <Grid item >
                        <Select
                            className="userProfile select-player-wrap"
                            sx={{
                                color: "white", ml: 1, minWidth: 100,
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                }
                            }}
                            onChange={(e) => { setProgressPlayerName(e.target.value) }}
                            value={progressPlayerName}
                            displayEmpty
                            MenuProps={MenuProps}
                        >
                            <MenuItem value="" >Player Name</MenuItem>
                            {progressChartPlayer.map((item) => (
                                <MenuItem
                                    value={item.name}
                                >
                                    {item.player.firstName}{" "}{item.player.lastName}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <div className="space-select-player hidden-mobile hidden-tab"></div>
                    <Grid item sm={12} className="date-picker" lg={'auto'} style={{ height: 55 }}>
                        <div className="calendarWrap">
                            <Grid container >
                                <Grid item >
                                    <input
                                        value={`${format(chartRange[0].startDate, "MM/dd/yyyy")} To ${format(chartRange[0].endDate, "MM/dd/yyyy")}`}
                                        readOnly
                                        className="inputBox"
                                        onClick={() => setOpen(!open)}
                                    />
                                </Grid>
                                <Grid item>
                                    <ArrowDropDownIcon sx={{ color: "white" }} />
                                </Grid>
                            </Grid>
                            <div ref={refOne}>
                                {open &&
                                    <Grid container className="calendarWrap">
                                        <Grid item xs={12} sm={12} md={12}>
                                            <DateRange sx={{ mt: 10 }}
                                                onChange={item => setChartRange([item.selection])}
                                                ranges={chartRange}
                                                months={1}
                                                rangeColors={["#882BBD"]}
                                                className="calendarElement-Progresschart"
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            </div>
                        </div>
                    </Grid>
                    <div className="progresschart-space hidden-mobile hidden-tab"></div>
                    <Grid item  >
                        <FormControl sx={{ minWidth: 150 }}>
                            <FormControlLabel className="select"
                                sx={{ color: "white", fontFamily: 'Lato sans-serif' }}
                                control={
                                    <Select
                                        className="userProfile select-player-wrap"
                                        sx={{
                                            color: "white", ml: 1, minWidth: 100,
                                            '.MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'rgba(228, 219, 233, 0.25)',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'rgba(228, 219, 233, 0.25)',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'rgba(228, 219, 233, 0.25)',
                                            },
                                            '.MuiSvgIcon-root ': {
                                                fill: "white !important",
                                            }
                                        }}
                                        multiple
                                        value={progressZone}
                                        onChange={(e) => { handleProgressZoneData(e.target.value) }}
                                        MenuProps={MenuProps}
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                        {selectedProgressZoneData.map((name, index) => (
                                            <MenuItem key={index} value={name}>
                                                <Checkbox checked={progressZone.indexOf(name) > -1} />
                                                <ListItemText primary={name} />
                                            </MenuItem>
                                        ))}
                                    </Select>}
                                label="Zone"
                                labelPlacement="start"
                            />
                        </FormControl>
                    </Grid>
                    <div className="progresschart-space hidden-mobile hidden-tab"></div>
                    <Grid item  >
                        <FormControl className="  userProfile" sx={{ minWidth: 200 }}>
                            <FormControlLabel className="  userProfile"
                                sx={{ color: "white", fontFamily: 'Lato sans-serif' }}
                                control={
                                    <Select
                                        className="  userProfile"
                                        sx={{
                                            minWidth: 100, ml: 1,
                                            color: "white", fontFamily: 'Lato sans-serif',
                                            '.MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'rgba(228, 219, 233, 0.25)',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'rgba(228, 219, 233, 0.25)',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'rgba(228, 219, 233, 0.25)',
                                            },
                                            '.MuiSvgIcon-root ': {
                                                fill: "white !important",
                                            }
                                        }}
                                        value={filterBy}
                                        onChange={(e) => { setFilterBy(e.target.value) }}
                                    >
                                        <MenuItem value={"drill"}>
                                            Drill
                                        </MenuItem>
                                        <MenuItem value={"match"}>Match</MenuItem>
                                    </Select>}
                                label="Filter By"
                                labelPlacement="start"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container className="Progress_margin">
                    {displayPlayer !== false ?
                        <Grid container>
                            <Grid item lg={12} sm={12} xs={12}>
                                <Paper className="bg-chart" elevation={0} square>
                                    {
                                        sessionData.isFetching ?
                                            < div className="chart_container">
                                                <CircularProgress size="30px" className='show_active margin_progress ' />
                                            </div>
                                            :
                                            <Charts
                                                key={"progressChart_123"}
                                                progressChartData={progressChartData}
                                                selectedLineChartMetric={selectedProgressChartMetric} />
                                    }
                                </Paper>
                            </Grid>
                        </Grid> :
                        <Grid item lg={12}>
                            <Paper className="bg-chart" elevation={0} square>
                                <div className="sqade">
                                    <p></p>
                                </div>
                            </Paper>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </>
    )
}