import { groupActionType } from "../actions/groupAction"

const dataTemplate = {
    isFetching: false,
    payload: [],
    error: false,
    errorMessage: ""
}
const initialData = {
    addGroup: dataTemplate,
    groupData: dataTemplate,
    singleGroup: dataTemplate,
    updateGroup: dataTemplate,
    deleteGroup: dataTemplate

}
export const GroupReducer = (state = initialData, action) => {
    switch (action.type) {
        //add group
        case groupActionType.ADD_NEW_GROUP:
            return {
                ...state,
                addGroup: {
                    ...state.addGroup,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case groupActionType.ADD_NEW_GROUP_SUCCESS:
            return {
                ...state,
                addGroup: {
                    ...state.addGroup,
                    isFetching: false,
                    payload: action.payload,
                    error: false
                }
            }
        case groupActionType.ADD_NEW_GROUP_ERROR:
            return {
                ...state,
                addGroup: {
                    ...state.addGroup,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        //fetch groups
        case groupActionType.FETCH_GROUP_LIST:
            return {
                ...state,
                groupData: {
                    ...state.groupData,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case groupActionType.FETCH_GROUP_LIST_SUCCESS:
            return {
                ...state,
                groupData: {
                    ...state.groupData,
                    isFetching: false,
                    payload: action.payload,
                    error: false
                }
            }
        case groupActionType.FETCH_GROUP_LIST_ERROR:
            return {
                ...state,
                groupData: {
                    ...state.groupData,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        //fetch single group
        case groupActionType.FETCH_SINGLE_GROUP:
            return {
                ...state,
                singleGroup: {
                    ...state.singleGroup,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case groupActionType.FETCH_SINGLE_GROUP_SUCCESS:
            return {
                ...state,
                singleGroup: {
                    ...state.singleGroup,
                    isFetching: false,
                    payload: action.payload,
                    error: false
                }
            }
        case groupActionType.FETCH_SINGLE_GROUP_ERROR:
            return {
                ...state,
                singleGroup: {
                    ...state.singleGroup,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        //update group
        case groupActionType.UPDATE_GROUP:
            return {
                ...state,
                updateGroup: {
                    ...state.updateGroup,
                    isFetching: true,
                    payload: [],
                    error: false,
                }
            }
        case groupActionType.UPDATE_GROUP_SUCCESS:
            return {
                ...state,
                updateGroup: {
                    ...state.updateGroup,
                    isFetching: false,
                    payload: action.payload,
                    error: false,
                }
            }
        case groupActionType.UPDATE_GROUP_ERROR:
            return {
                ...state,
                updateGroup: {
                    ...state.updateGroup,
                    isFetching: false,
                    error: true,
                    errorMessage: action.error
                }
            }
        //delete group
        case groupActionType.DELETE_GROUP:
            return {
                ...state,
                deleteGroup: {
                    ...state.deleteGroup,
                    isFetching: true,
                    payload: [],
                    error: false,
                }
            }
        case groupActionType.DELETE_GROUP_SUCCESS:
            return {
                ...state,
                deleteGroup: {
                    ...state.deleteGroup,
                    isFetching: false,
                    payload: action.payload,
                    error: false,
                }
            }
        case groupActionType.DELETE_GROUP_ERROR:
            return {
                ...state,
                deleteGroup: {
                    ...state.deleteGroup,
                    isFetching: false,
                    error: true,
                    errorMessage: action.error
                }
            }
        default:
            return state;
    }
}