import { IconButton, MenuItem, MenuList, Paper, Tooltip } from "@mui/material";
import React from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { LineCharts, ProgressLineChart, multipleSessionLineChart, periodLineChart } from "./chartHelper";

export const ChartTooltip = ({ combo, lineChartData, progressChartData, handleSortedData, selectedLineChartMetric
  , sessionDropdown, noOfSession }) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  useEffect(() => {
    document.addEventListener("click", hideOnClickOutside, true)
  }, [])
  const hideOnClickOutside = (e) => {
    if (anchorRef.current && !anchorRef.current.contains(e.target)) {
      setOpen(false)
    }
  }
  const dateComparison = (a, b) => {
    const date1 = new Date(a.startTime)
    const date2 = new Date(b.startTime)

    return date1 - date2;
  }
  const handleFilterComboChart = () => {
    if(combo){
      if (sessionDropdown === 2 || sessionDropdown === 5) {
        const sampleData = [...lineChartData];
        sampleData.sort(dateComparison)
        let sampleSortedData = periodLineChart([...sampleData], [...selectedLineChartMetric]);
        handleSortedData(sampleSortedData)
      }
      else if (sessionDropdown === 4) {
        const sampleData = [...lineChartData];
        sampleData.sort(dateComparison)
        let sampleSortedData = multipleSessionLineChart([...sampleData], [...selectedLineChartMetric], noOfSession);
        handleSortedData(sampleSortedData)
      }
      else {
        const sampleData = [...lineChartData];
        sampleData.sort(dateComparison)
        let sampleSortedData = LineCharts([...sampleData], [...selectedLineChartMetric]);

        handleSortedData(sampleSortedData)

      }

      setOpen(false)
    }
  }
  const handleFilterProgressChart = () => {
    const sampleData = [...progressChartData];
    sampleData.sort(dateComparison);
    let sampleSortedData = ProgressLineChart([...sampleData], [...selectedLineChartMetric])
    handleSortedData(sampleSortedData)
    setOpen(false)
  }
  return (
    <div >
      <Tooltip title="Sort">
        <IconButton onClick={() => setOpen(!open)}>
          <MoreVertIcon sx={{ color: "white" }} fontSize="small" />

        </IconButton>
      </Tooltip>
      <div ref={anchorRef} className="sort">
        {open &&
          <Paper className="sort_options">
            {/* <p>Sort</p> */}
            <MenuList>
              <MenuItem
                onClick={(combo) ? (e) => handleFilterComboChart() : (e) => handleFilterProgressChart()}
              >
                Start Time
              </MenuItem>
            </MenuList>
          </Paper>

        }
      </div>
    </div>
  )
}