import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { ICON_URL } from "../../config/config";
export const GaugeBox = ({ gauge, drillKpis, sessionDropdown ,numberOfSessions}) => {
    const [avarage, setAvarage] = useState('')
    useEffect(() => {
        if (sessionDropdown === 1) {
            let sum = 0;
            drillKpis.forEach(element => {
                sum += element[gauge.id]
            });
            setAvarage(Math.round(sum));
        }
        else if (sessionDropdown === 2) {
            let sum = 0;
            drillKpis.forEach(element => {
                sum += element[gauge.id]
            });
            setAvarage(Math.round(sum / drillKpis.length))
        }
        else if (sessionDropdown === 3) {
            let sum = 0;
            drillKpis.forEach(element => {
                sum += element[gauge.id]
            });
            setAvarage(Math.round(sum / drillKpis.length))

        }
        else if (sessionDropdown === 4) {
            let sum = 0;
            drillKpis.forEach(element => {
                sum += element[gauge.id]
            });
            setAvarage(Math.round(sum))

        }
        else{
            let sum =0;
            drillKpis.forEach(element => {
                sum += element[gauge.id]
            });
            setAvarage(Math.round(sum/numberOfSessions))
        }
    }, [drillKpis], [sessionDropdown])
    return (
        <Grid item className="gauge-box" sx={{ m: 2 }} lg={2} sm={12} xs={12}>
            <img className="icon_style" src={ICON_URL + "/" + gauge.id + ".svg"} alt="icon" />
            <h6>{gauge.label}</h6>
            <p>{avarage}</p>
        </Grid>
    )
}