import React, { useEffect, useState } from "react";
import {
    Card, CardActionArea, CardContent, CardHeader, Checkbox,
    Dialog, Grid, IconButton, TextField
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import '../../styles/style.css'
import { CardButton } from '../../styles/styles'
export const ProgresschartDialog = (props) => {
    const [sampleProgresschart, setSampleProgresschart] = useState(props.selectedProgressChartMetric)
    useEffect(() => {
        setSampleProgresschart(props.selectedProgressChartMetric)
    }, [props.selectedProgressChartMetric])
    const getChecked = (id) => {
        const found = [...sampleProgresschart].find(item => item.id === id);
        return found || false;

    }
    const handleProgressChartData = (e, data) => {
        if (getChecked(data.id)) {
            const checked = sampleProgresschart.filter((item) => {
                return item.id !== data.id
            })
            setSampleProgresschart(checked)
        }
        else {
            if (sampleProgresschart.length >= 1) {
                alert("maximum  selection limit is 1")
            }
            else {
                let temp = [...sampleProgresschart];
                temp.push(data)
                setSampleProgresschart(temp)
            }
        }
    }
    const handleSave = () => {
        if (sampleProgresschart.length === 0) {
            alert("Please select atleast one metric")
        }
        else {
            props.handleProgressChartMetrics(sampleProgresschart)
            handleClose();
        }
    }
    const handleClose = () => {
        props.openProgressDialogClose();
        props.handleSearchClear();
    }
    return (
        <div>
            <Dialog
                open={props.openProgressDialog}
                scroll={'body'}
                onClose={() => handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                minWidth="md"
            >
                <Grid container >
                    <Grid item className="card-dialog">
                        <Card className="card-dialog">
                            <CardHeader
                                action={
                                    <Grid container>
                                        <Grid item>
                                            <TextField sx={{ input: { color: 'white' } }}
                                                color="secondary"
                                                size="small"
                                                placeholder="Search"
                                                onChange={(e) => props.searchWord(e.target.value, 'search_progress_chart')}
                                                value={props.searchInProgressChart}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon sx={{ color: "white" }} />
                                                        </InputAdornment>
                                                    ),
                                                }} />
                                        </Grid>
                                        <div className="space-btw-grid"></div>
                                        <div className="space-card-header hidden-mobile"></div>
                                        <Grid item >
                                            <IconButton
                                                onClick={() => handleClose()}
                                            >
                                                <CloseIcon sx={{ color: "white" }} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                }
                            />
                                                        <div className='divider_daterangepick'></div>

                            <CardContent>
                                <Grid container justify="flex-end"
                                    alignItems="center">
                                    <Grid item lg={12} justify="flex-end"
                                        alignItems="center" >
                                        <div className="dialog_table_div">
                                            <table className="table Chart-table" style={{ color: "#fff" }} >
                                                <thead >
                                                    <tr>
                                                        <th className="thborder">#</th>
                                                        <th className="thborder">Metric</th>
                                                        <th className="thborder">Show</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {props.tableGuageBox.map((row, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{row.label}</td>
                                                            <td><Checkbox sx={{
                                                                color: "white",
                                                                '&.Mui-checked': {
                                                                    color: "white",
                                                                    backgroundColor: "#2B3040"
                                                                },
                                                            }} size="small"
                                                                checked={getChecked(row.id)}
                                                                onChange={(e) => { handleProgressChartData(e, row) }}
                                                            /></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActionArea>
                                <Grid container className="dialog_action_area">
                                    <div className="space-card-button hidden-mobile hidden-tab"></div>
                                    <Grid item>
                                        <CardButton variant="contained" className="dialog_button"
                                            onClick={handleSave}
                                        >
                                            Save
                                        </CardButton>
                                    </Grid>
                                </Grid>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
            </Dialog>
        </div>
    )
}