import { playerActionType } from "../actions/playerAction"

const dataTemplate = {
    isFetching: false,
    payload: [],
    error: false,
    errorMessage: ""
}
const initialData = {
    playerList: dataTemplate,
    selectedPlayer: [],

}
const modifyPlayers = (data) => data.map((item) => ({
    ...item, isSelected: true
}))
export const PlayerReducer = (state = initialData, action) => {
    switch (action.type) {
        //fetch groups
        case playerActionType.FETCH_PLAYER_LIST:
            return {
                ...state,
                playerList: {
                    ...state.playerList,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case playerActionType.FETCH_PLAYER_LIST_SUCCESS:
            return {
                ...state,
                playerList: {
                    ...state.playerList,
                    isFetching: false,
                    payload: action.payload,

                    error: false

                }
                , selectedPlayer: modifyPlayers(action.payload.data.players)
            }
        case playerActionType.FETCH_PLAYER_LIST_ERROR:
            return {
                ...state,
                playerList: {
                    ...state.playerList,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        case playerActionType.UPDATE_SELECTED_PLAYERS:
            return {
                ...state,
                selectedPlayer: action.data
            }
        default:
            return state;
    }
}