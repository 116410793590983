export const playerActionType = {

    // get playerlist
    FETCH_PLAYER_LIST: "FETCH_PLAYER_LIST",
    FETCH_PLAYER_LIST_SUCCESS: "FETCH_PLAYER_LIST_SUCCESS",
    FETCH_PLAYER_LIST_ERROR: "FETCH_PLAYER_LIST_ERROR",

    UPDATE_SELECTED_PLAYERS: "UPDATE_SELECTED_PLAYERS",

}
export const fetchPlayerList = (id) => {
    return {
        type: playerActionType.FETCH_PLAYER_LIST,
        id: id
    }
}
export const fetchPlayerListSuccess = (data) => {
    return {
        type: playerActionType.FETCH_PLAYER_LIST_SUCCESS,
        payload: data
    }
}
export const fetchPlayerListError = (error) => {
    return {
        type: playerActionType.FETCH_PLAYER_LIST_ERROR,
        error
    }
}
export const updateSelectedPlayers = (data) => {
    return {
        type: playerActionType.UPDATE_SELECTED_PLAYERS,
        data
    }
}