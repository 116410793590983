export const individualSessionGuageBox = (selectedSession, selectedDriils, selectedSessionName, temp) => {
    let tempIndividualSession = [];
    //guagebox
    [...selectedSession].forEach(((obj) => {
        [...selectedDriils].forEach((ele) => {
            if (obj.id === selectedSessionName && obj.id === ele.sessionId) {
                obj.sessionPlayers.forEach((item) => {
                    item.drills.forEach((data) => {
                        if (temp === item.playerDetails.firstName + item.playerDetails.lastName)
                            if (ele.isDrill && ele.isChecked) {
                                if (ele.drillName === data.drillName) {
                                    tempIndividualSession.push(data.drillKpi)
                                }
                            }
                    })
                })
            }
        })
    }
    ))
    return tempIndividualSession
}
export const individualSessionTime = (selectedSession, selectedDriils, selectedSessionName, temp) => {
    let tempIndividualTime = [];
    //guagebox
    [...selectedSession].forEach(((obj) => {
        [...selectedDriils].forEach((ele) => {
            if (obj.id === selectedSessionName && obj.id === ele.sessionId) {
                obj.sessionPlayers.forEach((item) => {
                    item.drills.forEach((data) => {
                        if (temp === item.playerDetails.firstName + item.playerDetails.lastName)
                            if (ele.isDrill && ele.isChecked) {
                                if (ele.drillName === data.drillName) {
                                    tempIndividualTime.push(data)
                                }
                            }
                    })

                })
            }
        })
    }
    ))
    return tempIndividualTime;
}
export const individualLineChart = (selectedSession, selectedDriils, selectedSessionName, temp) => {
    const tempdrill = [];
    //linechar+table
    [...selectedSession].forEach(((obj) => {
        [...selectedDriils].forEach((ele) => {
            if (obj.id === selectedSessionName && obj.id === ele.sessionId) {
                obj.sessionPlayers.forEach((obj) => {
                    (obj.drills).forEach((item) => {
                        if (temp === obj.playerDetails.firstName + obj.playerDetails.lastName) {
                            if (ele.isDrill && ele.isChecked) {
                                if (ele.drillName === item.drillName) {
                                    tempdrill.push({
                                        name: item.drillName,
                                        startTime: item.startTime,
                                        ...item.drillKpi
                                    })
                                }
                            }
                        }
                    })
                })
            }
        })
    }))
    return tempdrill;
}
export const individualPeriodGuageBox = (selectedSession, selectedDriils, temp) => {
    let tempIndividualPeriod = [];
    [...selectedSession].forEach(((item) => {
        [...selectedDriils].forEach((ele) => {
            if (ele.sessionId) {
                item.sessionPlayers.forEach((data) => {
                    if (temp[0] === data.playerDetails.firstName + data.playerDetails.lastName) {
                        data.drills.forEach((x) => {
                            if (ele.isDrill && ele.isChecked) {
                                if (ele.drillName === x.drillName) {
                                    tempIndividualPeriod.push(x.drillKpi)
                                }
                            }
                        })
                    }
                })
            }
        }
        )
    }))
    return tempIndividualPeriod;
}
export const individualPeriodTime = (selectedSession, selectedDriils, temp) => {
    let tempIndividualPeriodTime = [];
    [...selectedSession].forEach(((item) => {
        [...selectedDriils].forEach((ele) => {
            if (ele.sessionId) {
                item.sessionPlayers.forEach((data) => {
                    if (temp[0] === data.playerDetails.firstName + data.playerDetails.lastName) {
                        data.drills.forEach((x) => {
                            if (ele.isDrill && ele.isChecked) {
                                if (ele.drillName === x.drillName) {
                                    tempIndividualPeriodTime.push(x)
                                }
                            }
                        })
                    }
                })
            }
        }
        )
    }))
    return tempIndividualPeriodTime;
}
export const individualPeriodLineChart = (selectedSession, selectedDriils, temp,noOfSession) => {
    let tempDrill = [];
    [...selectedSession].forEach(((data,index) => {
        [...selectedDriils].forEach((ele) => {
            if (ele.sessionId) {
                data.sessionPlayers.forEach((obj) => {
                    (obj.drills).forEach((item) => {
                        if (temp[0] === obj.playerDetails.firstName + obj.playerDetails.lastName) {
                            if (ele.isDrill && ele.isChecked) {
                                if (ele.drillName === item.drillName) {
                                    if (!tempDrill[data.id]) {
                                        tempDrill[data.id] = {
                                            id: data.id,
                                            name: data.sessionName,
                                            startTime: data.sessionDetails.startTime,
                                            ...item.drillKpi
                                        }
                                    } else {
                                        Object.keys(item.drillKpi).forEach((obj) => {
                                            tempDrill[data.id][obj] += item.drillKpi[obj];
                                            
                                        })
                                    }
                                }
                            }
                        }
                    })
                })
            }
        })
    }))
    return tempDrill;
}
export const SessionGuageBox = (selectedSession, selectedDriils, selectedSessionName, playerList) => {
    let tempSession = [];
    [...selectedSession].forEach(((data) => {
        [...selectedDriils].forEach((ele) => {
            if (data.id === selectedSessionName && data.id === ele.sessionId) {
                data.sessionPlayers.forEach((obj) => {
                    obj.drills.forEach((item) => {
                        if (ele.isDrill && ele.isChecked) {
                            if (ele.drillName === item.drillName) {
                                [...playerList].forEach((x) => {
                                    if (x.name === obj.playerDetails.firstName + obj.playerDetails.lastName) {
                                        tempSession.push(item.drillKpi);
                                    }
                                })
                            }
                        }
                    })
                })
            }
        })
    }))
    return tempSession;
}
export const SessionTime = (selectedSession, selectedDriils, selectedSessionName) => {
    let tempSessionTime = [];
    [...selectedSession].forEach(((data) => {
        if (selectedSessionName === data.id)
            tempSessionTime.push(data.sessionDetails, data)

    }))
    return tempSessionTime;
}
export const sessionLinechart = (selectedSession, selectedDriils, selectedSessionName, playerList) => {
    let tempSessionDrills = [];
    [...selectedSession].forEach((data) => {
        [...selectedDriils].forEach((obj) => {
            if (data.id === selectedSessionName && data.id === obj.sessionId) {
                data.sessionPlayers.forEach((item) => {
                    item.drills.forEach((ele) => {
                        [...playerList].forEach((x) => {
                            if (x.name === item.playerDetails.firstName + item.playerDetails.lastName && x.isSelected) {
                                if (obj.isDrill && obj.isChecked) {
                                    if (ele.drillName === obj.drillName) {
                                        if (!tempSessionDrills[item.id]) {
                                            tempSessionDrills[item.id] = {
                                                id: item.id,
                                                startTime: data.sessionDetails.startTime,
                                                name: item.playerDetails['firstName'] + " " + item.playerDetails['lastName'],
                                                ...ele.drillKpi
                                            }
                                        }
                                        else {
                                            Object.keys(ele.drillKpi).forEach((obj) => {
                                                tempSessionDrills[item.id][obj] += ele.drillKpi[obj];
                                            })
                                        }
                                    }
                                }
                            }
                        })
                    }
                    )
                })
            }
        })
    })
    return tempSessionDrills;
}
export const MultipleSessionGuageBox = (selectedSession, selectedDriils, playerList) => {
    let tempMultipleSession = [];
    [...selectedSession].forEach(((data) => {
        [...selectedDriils].forEach((ele) => {
            data.sessionPlayers.forEach((obj) => {
                obj.drills.forEach((item) => {
                    if (ele.isDrill && ele.isChecked) {
                        if (ele.drillName === item.drillName) {
                            [...playerList].forEach((x) => {
                                if (x.name === obj.playerDetails.firstName + obj.playerDetails.lastName) {
                                    tempMultipleSession.push(item.drillKpi);
                                }
                            })
                        }
                    }
                })
            })
        })
    }))
    return tempMultipleSession;
}
export const MultipleSessionTime = (selectedSession, selectedDriils, selectedSessionName) => {
    let tempMultipleSessionTime = [];
    [...selectedSession].forEach(((data) => {

        tempMultipleSessionTime.push(data.sessionDetails, data)

    }))
    return tempMultipleSessionTime;
}
export const multipleSessionLinechart = (selectedSession, selectedDriils, playerList) => {
    let tempMultipleSessionDrills = [];
    [...selectedSession].forEach((data) => {
        [...selectedDriils].forEach((obj) => {
            data.sessionPlayers.forEach((item) => {
                item.drills.forEach((ele) => {
                    [...playerList].forEach((x) => {
                        if (x.name === item.playerDetails.firstName + item.playerDetails.lastName && x.isSelected) {
                            if (obj.isDrill && obj.isChecked) {
                                if (ele.drillName === obj.drillName) {
                                    if (!tempMultipleSessionDrills[item.playerDetails.firstName + item.playerDetails.lastName]) {
                                        tempMultipleSessionDrills[item.playerDetails.firstName + item.playerDetails.lastName] = {
                                            id: item.playerDetails.firstName + item.playerDetails.lastName,
                                            startTime: data.sessionDetails.startTime,
                                            name: item.playerDetails['firstName'] + " " + item.playerDetails['lastName'],
                                            ...ele.drillKpi
                                        }
                                    }
                                    else {
                                        Object.keys(ele.drillKpi).forEach((obj) => {
                                            tempMultipleSessionDrills[item.playerDetails.firstName + item.playerDetails.lastName][obj] += ele.drillKpi[obj];
                                        })
                                    }
                                }
                            }
                        }
                    })
                }
                )
            })
        })
    })
    return tempMultipleSessionDrills;
}
export const SquadPeriodGuageBox = (selectedSession, selectedDriils, playerList) => {
    let tempSquadPriod = [];
    [...selectedSession].forEach(((data) => {
        [...selectedDriils].forEach((ele) => {
            data.sessionPlayers.forEach((obj) => {
                obj.drills.forEach((item) => {
                    if (ele.isDrill && ele.isChecked) {
                        if (ele.drillName === item.drillName) {
                            [...playerList].forEach((x) => {
                                if (x.name === obj.playerDetails.firstName + obj.playerDetails.lastName) {
                                    tempSquadPriod.push(item.drillKpi);
                                }
                            })
                        }
                    }
                })
            })
        })
    }))
    return tempSquadPriod;

}
export const SquadPeriodLinechart = (selectedSession, selectedDriils, playerList,noOfPlayers) => {
    let tempSquadPriodDrills = [];
    [...selectedSession].forEach(((data) => {
     
        [...selectedDriils].forEach((obj) => {
            data.sessionPlayers.forEach((item) => {
                item.drills.forEach((ele) => {
                    [...playerList].forEach((x) => {
                        if (x.name === item.playerDetails.firstName + item.playerDetails.lastName && x.isSelected) {
                            if (obj.isDrill && obj.isChecked) {
                                if (ele.drillName === obj.drillName) {
                                    
                                    if(!tempSquadPriodDrills[data.id]){
                                     
                                        tempSquadPriodDrills[data.id]={
                                            id:data.id,
                                            startTime: data.sessionDetails.startTime,
                                            name:data.sessionName,
                                            ...ele.drillKpi
                                        }
                                    }
                                    else {
                                        Object.keys(ele.drillKpi).forEach((obj) => {

                                            tempSquadPriodDrills[data.id][obj] += ele.drillKpi[obj]/noOfPlayers;
                                        
                                        })
                                    }
                                }
                            }
                        }
                    })})                 
            })
        })
    }))
    return tempSquadPriodDrills;
}