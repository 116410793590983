export const handleSearchword = (filterParams, searchTableGuageBox) => {
    let filter = searchTableGuageBox.filter(item => {
        if (filterParams['keyWord'] !== '' && filterParams['pichart_first'] !== '' &&
            filterParams['pichart_second'] !== '' && filterParams['progressChart'] !== ''
            && filterParams['lineChart'] !== '') {
            return item.label.toLowerCase().includes(filterParams['keyWord'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['pichart_first'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['pichart_second'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['progressChart'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['lineChart'].toLowerCase());
        }
        else if (filterParams['keyWord'] !== '' && filterParams['pichart_first'] !== '' &&
            filterParams['pichart_second'] !== '' && filterParams['progressChart'] !== '') {
            return item.label.toLowerCase().includes(filterParams['keyWord'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['pichart_first'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['pichart_second'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['progressChart'].toLowerCase());
        }
        else if (filterParams['keyWord'] !== '' && filterParams['pichart_first'] !== '' &&
            filterParams['pichart_second'] !== '' && filterParams['lineChart'] !== '') {
            return item.label.toLowerCase().includes(filterParams['keyWord'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['pichart_first'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['pichart_second'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['lineChart'].toLowerCase());
        }
        else if (filterParams['keyWord'] !== '' && filterParams['pichart_first'] !== '' &&
            filterParams['progressChart'] !== '' && filterParams['lineChart'] !== '') {
            return item.label.toLowerCase().includes(filterParams['keyWord'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['pichart_first'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['progressChart'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['lineChart'].toLowerCase());
        }
        else if (filterParams['keyWord'] !== '' && filterParams['pichart_second'] !== '' &&
            filterParams['progressChart'] !== '' && filterParams['lineChart'] !== '') {
            return item.label.toLowerCase().includes(filterParams['keyWord'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['pichart_second'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['progressChart'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['lineChart'].toLowerCase());
        }
        else if (filterParams['pichart_first'] !== '' && filterParams['pichart_second'] !== '' &&
            filterParams['progressChart'] !== '' && filterParams['lineChart'] !== '') {
            return item.label.toLowerCase().includes(filterParams['pichart_first'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['pichart_second'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['progressChart'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['lineChart'].toLowerCase());
        }
        else if (filterParams['keyWord'] !== '' && filterParams['pichart_first'] !== '' &&
            filterParams['pichart_second'] !== '') {
            return item.label.toLowerCase().includes(filterParams['keyWord'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['pichart_first'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['pichart_second'].toLowerCase());
        }
        else if (filterParams['keyWord'] !== '' && filterParams['pichart_first'] !== '' &&
            filterParams['progressChart'] !== '') {
            return item.label.toLowerCase().includes(filterParams['keyWord'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['pichart_first'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['progressChart'].toLowerCase());
        }
        else if (filterParams['keyWord'] !== '' && filterParams['pichart_first'] !== '' &&
            filterParams['lineChart'] !== '') {
            return item.label.toLowerCase().includes(filterParams['keyWord'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['pichart_first'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['lineChart'].toLowerCase());
        }
        else if (filterParams['keyWord'] !== '' && filterParams['progressChart'] !== '' &&
            filterParams['pichart_second'] !== '') {
            return item.label.toLowerCase().includes(filterParams['keyWord'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['progressChart'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['pichart_second'].toLowerCase());
        }
        else if (filterParams['keyWord'] !== '' && filterParams['lineChart'] !== '' &&
            filterParams['pichart_second'] !== '') {
            return item.label.toLowerCase().includes(filterParams['keyWord'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['lineChart'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['pichart_second'].toLowerCase());
        }
        else if (filterParams['keyWord'] !== '' && filterParams['lineChart'] !== '' &&
            filterParams['progressChart'] !== '') {
            return item.label.toLowerCase().includes(filterParams['keyWord'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['lineChart'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['progressChart'].toLowerCase());
        }

        else if (filterParams['pichart_first'] !== '' &&
            filterParams['pichart_second'] !== '' && filterParams['progressChart'] === '') {
            return item.label.toLowerCase().includes(filterParams['pichart_first'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['pichart_second'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['progressChart'].toLowerCase());
        }
        else if (filterParams['pichart_first'] !== '' &&
            filterParams['pichart_second'] !== '' && filterParams['lineChart'] === '') {
            return item.label.toLowerCase().includes(filterParams['pichart_first'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['pichart_second'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['lineChart'].toLowerCase());
        }
        else if (filterParams['pichart_first'] !== '' &&
            filterParams['progressChart'] !== '' && filterParams['lineChart'] === '') {
            return item.label.toLowerCase().includes(filterParams['pichart_first'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['progressChart'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['lineChart'].toLowerCase());
        }
        else if (filterParams['pichart_second'] !== '' &&
            filterParams['progressChart'] !== '' && filterParams['lineChart'] === '') {
            return item.label.toLowerCase().includes(filterParams['pichart_second'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['progressChart'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['lineChart'].toLowerCase());
        }
        else if (filterParams['keyWord'] !== '' && filterParams['pichart_first'] !== '') {
            return item.label.toLowerCase().includes(filterParams['keyWord'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['pichart_first'].toLowerCase());
        }
        else if (filterParams['keyWord'] !== '' && filterParams['pichart_second'] !== '') {
            return item.label.toLowerCase().includes(filterParams['keyWord'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['pichart_second'].toLowerCase());

        }
        else if (filterParams['keyWord'] !== '' && filterParams['progressChart'] !== '') {
            return item.label.toLowerCase().includes(filterParams['keyWord'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['progressChart'].toLowerCase());
        }
        else if (filterParams['keyWord'] !== '' && filterParams['lineChart'] !== '') {
            return item.label.toLowerCase().includes(filterParams['keyWord'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['lineChart'].toLowerCase());
        }
        else if (filterParams['pichart_first'] !== '' && filterParams['pichart_second'] !== '') {
            return item.label.toLowerCase().includes(filterParams['pichart_first'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['pichart_second'].toLowerCase());
        }
        else if (filterParams['pichart_first'] !== '' && filterParams['progressChart'] !== '') {
            return item.label.toLowerCase().includes(filterParams['pichart_first'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['progressChart'].toLowerCase());
        }
        else if (filterParams['pichart_first'] !== '' && filterParams['lineChart'] !== '') {
            return item.label.toLowerCase().includes(filterParams['pichart_first'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['lineChart'].toLowerCase());
        }
        else if (filterParams['progressChart'] !== '' && filterParams['pichart_second'] !== '') {
            return item.label.toLowerCase().includes(filterParams['progressChart'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['pichart_second'].toLowerCase());
        }
        else if (filterParams['lineChart'] !== '' && filterParams['pichart_second'] !== '') {
            return item.label.toLowerCase().includes(filterParams['lineChart'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['pichart_second'].toLowerCase());
        }
        else if (filterParams['progressChart'] !== '' && filterParams['lineChart'] !== '') {
            return item.label.toLowerCase().includes(filterParams['progressChart'].toLowerCase()) &&
                item.label.toLowerCase().includes(filterParams['lineChart'].toLowerCase());
        }
        else if (filterParams['keyWord'] !== '') {

            return item.label.toLowerCase().includes(filterParams['keyWord'].toLowerCase());
        }
        else if (filterParams['pichart_first'] !== '') {
            return item.label.toLowerCase().includes(filterParams['pichart_first'].toLowerCase());
        }
        else if (filterParams['progressChart'] !== '') {
            return item.label.toLowerCase().includes(filterParams['progressChart'].toLowerCase());
        }
        else if (filterParams['lineChart'] !== '') {
            return item.label.toLowerCase().includes(filterParams['lineChart'].toLowerCase());
        }
        else
            return item.label.toLowerCase().includes(filterParams['pichart_second'].toLowerCase())
    }
    )
    return filter
}