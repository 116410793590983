export const dashboardActionType = {

    // get sessions
    FETCH_SESSION: "FETCH_SESSION",
    FETCH_SESSION_SUCCESS: "FETCH_SESSION_SUCCESS",
    FETCH_SESSION_ERROR: "FETCH_SESSION_ERROR",

    FETCH_SESSION_PROGRESS: "FETCH_SESSION_PROGRESS",
    FETCH_SESSION_PROGRESS_SUCCESS: "FETCH_SESSION_PROGRESS_SUCCESS",
    FETCH_SESSION_PROGRESS_ERROR: "FETCH_SESSION_PROGRESS_ERROR",

    FETCH_SESSION_PI: "FETCH_SESSION_PI",
    FETCH_SESSION_PI_SUCCESS: "FETCH_SESSION_PI_SUCCESS",
    FETCH_SESSION_PI_ERROR: "FETCH_SESSION_PI_ERROR",

}
export const fetchSession = (data) => {
    return {
        type: dashboardActionType.FETCH_SESSION,
        data: data
    }
}
export const fetchSessionSuccess = (data) => {
    return {
        type: dashboardActionType.FETCH_SESSION_SUCCESS,
        payload: data
    }
}
export const fetchSessionError = (error) => {
    return {
        type: dashboardActionType.FETCH_SESSION_ERROR,
        error
    }
}
export const fetchSessionProgress = (data) => {
    return {
        type: dashboardActionType.FETCH_SESSION_PROGRESS,
        data: data
    }
}
export const fetchSessionProgressSuccess = (data) => {
    return {
        type: dashboardActionType.FETCH_SESSION_PROGRESS_SUCCESS,
        payload: data
    }
}
export const fetchSessionProgressError = (error) => {
    return {
        type: dashboardActionType.FETCH_SESSION_PROGRESS_ERROR,
        error
    }
}
export const fetchSessionPi = (data) => {
    return {
        type: dashboardActionType.FETCH_SESSION_PI,
        data: data
    }
}
export const fetchSessionPiSuccess = (data) => {
    return {
        type: dashboardActionType.FETCH_SESSION_PI_SUCCESS,
        payload: data
    }
}
export const fetchSessionPiError = (error) => {
    return {
        type: dashboardActionType.FETCH_SESSION_PI_ERROR,
        error
    }
}