export const userActionType = {

    // get user Profile
    FETCH_USER_PROFILE: "FETCH_USER_PROFILE",
    FETCH_USER_PROFILE_SUCCESS: "FETCH_USER_PROFILE_SUCCESS",
    FETCH_USER_PROFILE_ERROR: "FETCH_USER_PROFILE_ERROR"
}
export const fetchUserProfile = (id) => {
    return {
        type: userActionType.FETCH_USER_PROFILE,
        id: id
    }
}
export const fetchUserProfileSuccess = (data) => {
    return {
        type: userActionType.FETCH_USER_PROFILE_SUCCESS,
        payload: data
    }
}
export const fetchUserProfileError = (error) => {
    return {
        type: userActionType.FETCH_USER_PROFILE_ERROR,
        error
    }
}