export const groupActionType = {
    // create group
    ADD_NEW_GROUP: "ADD_NEW_GROUP",
    ADD_NEW_GROUP_SUCCESS: "ADD_NEW_GROUP_SUCCESS",
    ADD_NEW_GROUP_ERROR: "ADD_NEW_GROUP_ERROR",
    // fetch groups
    FETCH_GROUP_LIST: "FETCH_GROUP_LIST",
    FETCH_GROUP_LIST_SUCCESS: "FETCH_GROUP_LIST_SUCCESS",
    FETCH_GROUP_LIST_ERROR: "FETCH_GROUP_LIST_ERROR",
    // fetch single group
    FETCH_SINGLE_GROUP: "FETCH_SINGLE_GROUP",
    FETCH_SINGLE_GROUP_SUCCESS: "FETCH_SINGLE_GROUP_SUCCESS",
    FETCH_SINGLE_GROUP_ERROR: "FETCH_SINGLE_GROUP_ERROR",
    // update group
    UPDATE_GROUP: "UPDATE_GROUP",
    UPDATE_GROUP_SUCCESS: "UPDATE_GROUP_SUCCESS",
    UPDATE_GROUP_ERROR: "UPDATE_GROUP_ERROR",
    // delete group
    DELETE_GROUP: "DELETE_GROUP",
    DELETE_GROUP_SUCCESS: "DELETE_GROUP_SUCCESS",
    DELETE_GROUP_ERROR: "DELETE_GROUP_ERROR",
}
//create group
export const addNewGroup = (data) => {
    return {
        type: groupActionType.ADD_NEW_GROUP,
        data: data
    }
}
export const addNewGroupSuccess = (data) => {
    return {
        type: groupActionType.ADD_NEW_GROUP_SUCCESS,
        payload: data
    }
}
export const addNewGroupError = (error) => {
    return {
        type: groupActionType.ADD_NEW_GROUP_ERROR,
        error
    }
}
//fetch groups
export const fetchGroupList = () => {
    return {
        type: groupActionType.FETCH_GROUP_LIST,

    }
}
export const fetchGroupListSuccess = (data) => {
    return {
        type: groupActionType.FETCH_GROUP_LIST_SUCCESS,
        payload: data
    }
}
export const fetchGroupListError = (error) => {
    return {
        type: groupActionType.FETCH_GROUP_LIST_ERROR,
        error
    }
}
//fetch single group
export const fetchSingleGroup = (id) => {
    return {
        type: groupActionType.FETCH_SINGLE_GROUP,
        id: id
    }
}
export const fetchSingleGroupSuccess = (data) => {
    return {
        type: groupActionType.FETCH_SINGLE_GROUP_SUCCESS,
        payload: data
    }
}
export const fetchSingleGroupError = (error) => {
    return {
        type: groupActionType.FETCH_SINGLE_GROUP_ERROR,
        error
    }
}
//update group
export const updateGroup = (id, data) => {
    return {
        type: groupActionType.UPDATE_GROUP,
        id: id,
        data: data
    }
}
export const updateGroupSuccess = (data) => {
    return {
        type: groupActionType.UPDATE_GROUP_SUCCESS,
        payload: data
    }
}
export const UpdateGroupError = (error) => {
    return {
        type: groupActionType.UPDATE_GROUP_ERROR,
        error
    }
}
//delete group
export const deleteGroup = (id) => {
    return {
        type: groupActionType.DELETE_GROUP,
        id: id,
    }
}
export const deleteGroupSuccess = (data) => {
    return {
        type: groupActionType.DELETE_GROUP_SUCCESS,
        payload: data
    }
}
export const deleteGroupError = (error) => {
    return {
        type: groupActionType.DELETE_GROUP_ERROR,
        error
    }
}
