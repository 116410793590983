/* eslint-disable eol-last */
import axios from 'axios';
import { toastr } from 'react-redux-toastr';

let baseUrl = process.env.REACT_APP_API_BASE_URL + '/' + process.env.REACT_APP_API_VERSION;

/**
 * axios instance
 */
const instance = axios.create({
    baseURL: baseUrl
});


// request header
instance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => {
        Promise.reject(error)
    });

/**
 *
 * parse error response
 */
function parseError(messages) {
    // error
    if (messages) {
        if (messages instanceof Array) {
            return Promise.reject({ messages: messages })
        } else {
            return Promise.reject({ messages: [messages] })
        }
    } else {
        return Promise.reject({ messages: [] })
    }
}

// response parse
instance.interceptors.response.use((response) => {
    if (response.data.errorCode === -10) {
    }
    return response;
}, error => {
    if (error.response) {
        if (401 === error.response.status) {

        } else {
            return parseError(error.response.data)
        }
    }

    return Promise.reject({ messages: [{ 'message': 'Network error please try later' }] })
})

export const displayErrorMessage = (error, toastTitle) => {
    if (error.response) {// client received an error response (5xx, 4xx)
        toastr.error(toastTitle, error.response.data.message || error.response.data.errors)
    } else if (error.request) {
    } else if (error.messages) {
        error.messages.forEach(element => {
            if (element.errors) {
                element.errors.forEach(item => {
                    toastr.error(toastTitle, item.msg)
                })
            } else {
                toastr.error(toastTitle, element.message)
            }
        });
        // client never received a response, or request never left
    } else {
        // anything else
    }
    return false
}

export const API = instance