import { Button, IconButton } from '@mui/material';
import { purple } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
// import makeStyles from '@mui/styles';
export const theme = createTheme({
  palette: {
    secondary: {
      main: '#CCCCCC',
    },
  },
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 280,
    },
  },
};
export const CardButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  '&:hover': {
    backgroundColor: purple[700],
  },
}));
export const PlayerButton = styled(Button)({
  height: 55,
  border: '1px solid #666666',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  color: "#F1F1F1",
  lineHeight: 1.5,
  backgroundColor: '#212531',
  // borderColor: '#0063cc',
  fontFamily: [

    'Lato !important',

  ].join(','),
  '&:hover': {
    backgroundColor: '#212531',
    border: '1px solid #666666',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#212531',
    border: '1px solid #666666'
  },
  '&:focus': {
    boxShadow: "none",
  },
});
export const CloseIconButton = styled(IconButton)({
  width: 12, height: 12,
  backgroundColor: "#A622BB",
  // borderColor: '#0063cc',
  fontFamily: [

    'Lato',

  ].join(','),
  '&:hover': {
    backgroundColor: "#A622BB",
    // border: '1px solid #666666',
    // boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: "#A622BB",
    // border: '1px solid #666666'
  },
  '&:focus': {
    boxShadow: "none",
  },
});
export const SessionButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: "11.67px",
  padding: '6px 12px',
  border: '1px solid #A622BB ',
  borderRadius: '5px',
  lineHeight: "14px",
  backgroundColor: '#2B3040',
  borderColor: '#9c27b0',
  fontFamily: [
    'Exo 2',
  ].join(','),
  '&:hover': {
    border: '1px solid #A622BB ',
    backgroundColor: '#2B3040',
    //borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    border: '1px solid #A622BB ',
    backgroundColor: '#2B3040',
  },
  '&:focus': {
    // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    border: 'px solid #A622BB ',
    backgroundColor: '#2B3040',
  },
});
export const PlayerSelect = () => ({
  select: {
    color: "white", ml: 1, minWidth: 100,
    borderColor: 'rgba(228, 219, 233, 0.25)',
    "&:focus": {
      borderColor: 'rgba(228, 219, 233, 0.25)'
    },
    '&:active': {
      borderColor: 'rgba(228, 219, 233, 0.25)'

    },
  },
  icon: {
    fill: "white !important",
  },
})
export const stringToColor = (string) => {
  let hash = 0;
  let i;
  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
}

export const stringAvatar = (name) => {
  if (name) {
    let temp = name.toString()
    return {
      sx: {
        bgcolor: stringToColor(temp),
      }
      ,
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }
}
export const wrapper = {
  height: '80%',
  overflow: 'auto',
  '*::-webkit-scrollbar': {
    width: '0.4em',
  },
  '*::-webkit-scrollbar-track': {
    '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,.1)',
    outline: '1px solid slategrey',
  },
}
export const selectStyle = {

  color: "white",
  fontFamily: 'Exo 2',
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: "transparent",

    backgroundColor: "transparent",
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: "transparent",

    backgroundColor: "transparent",
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: "transparent",

    backgroundColor: "transparent",
  },
  '.MuiSvgIcon-root ': {
    fill: "white !important",
  }
}

