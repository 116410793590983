import { all, put, takeLatest } from "redux-saga/effects";
import { fetchSessionDetails } from "../../services/dashboardServices";
import { dashboardActionType, fetchSessionError, fetchSessionPiError, fetchSessionPiSuccess, fetchSessionProgressError, fetchSessionProgressSuccess, fetchSessionSuccess } from "../actions/dashboardAction";

// fetch sessions
function* _fetchSessionData(action) {
    try {
        const sessions = yield (fetchSessionDetails(action.data));
        yield put(fetchSessionSuccess(sessions))
    }
    catch (e) {
        yield put(fetchSessionError("error"))
    }
}
function* fetchSessionData() {
    yield takeLatest(dashboardActionType.FETCH_SESSION, _fetchSessionData)
}
// fetch progress sessions
function* _fetchProgressSessionData(action) {
    try {
        const sessions = yield (fetchSessionDetails(action.data));
        yield put(fetchSessionProgressSuccess(sessions))
    }
    catch (e) {
        yield put(fetchSessionProgressError("error"))
    }
}
function* fetchProgressSessionData() {
    yield takeLatest(dashboardActionType.FETCH_SESSION_PROGRESS, _fetchProgressSessionData)
}
// fetch pi sessions
function* _fetchPiSessionData(action) {
    try {
        const sessions = yield (fetchSessionDetails(action.data));
        yield put(fetchSessionPiSuccess(sessions))
    }
    catch (e) {
        yield put(fetchSessionPiError("error"))
    }
}
function* fetchPiSessionData() {
    yield takeLatest(dashboardActionType.FETCH_SESSION_PI, _fetchPiSessionData)
}
export function* DashboardSaga() {
    yield all([fetchSessionData(), fetchProgressSessionData(), fetchPiSessionData()])
}