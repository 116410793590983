/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable */ 
import React, { useState, useEffect } from "react";
import { Grid, IconButton, MenuItem, Paper, Select } from "@mui/material";
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { Box, ThemeProvider } from "@mui/system";
import { Header } from "../sharable/header";
import '../../images/sessions.png';
import '../../images/timer-line.png';
import '../../images/player-name.png';
import '../../images/list-settings.png'
import "../../images/info-btn.png";
import "../../images/sprint-icon.png";
import '../../images/arrow-up.png';
import '../../images/loading-icon.png';
import '../../images/arrow-down.png';
import '../../images/max-speed.png';
import '../../images/char-icont.png';
import '../../images/match_name.png';
import { Charts } from "../sharable/charts";
import { Tables } from "../sharable/tables";
import '../../styles/style.css'
import { useDispatch, useSelector } from "react-redux";
import { theme, MenuProps, } from '../../styles/styles'
import moment from "moment/moment";
import { fetchMetricList } from "../../redux/actions/metricsAction";
import { GaugeBox } from "../dashboard/gaugebox";
import { GaugeboxDialog } from "../dialogbox/gaugeboxDialog";
import { LineChartDialog } from "../dialogbox/lineChartDialog";
import { DrillSelectionBox } from "../dashboard/drillSelectionBox";
import {
    MultipleSessionGuageBox,
    MultipleSessionTime,
    SessionGuageBox, SessionTime, SquadPeriodGuageBox, SquadPeriodLinechart, individualLineChart, individualPeriodGuageBox,
    individualPeriodLineChart, individualPeriodTime, individualSessionGuageBox, individualSessionTime,
    multipleSessionLinechart,
    sessionLinechart
} from "../../helpers/dashboardChartGaugeHelper";
import { handleSearchword } from "../../helpers/dashboardSearch";
import { DashboardProgressChart } from "./dashboardProgressChart";
import { DashboardPiChart } from "./dashboardPiChart";

export const Dashboard = () => {
    const dispatch = useDispatch();
    const [players, setPlayers] = useState([]);
    const [playerList, setPlayerList] = useState([]);
    const [playerName, setPlayerName] = useState('');
    const [displayChart, setDisplayChart] = useState(true);
    const metricListData = useSelector(state => state.metrics.metricList);
    //card payer+squad
    const [search, setSearch] = useState('');
    const [searchInFristPiChart, setSearchInFristPiChart] = useState('');
    const [searchInSecondPiChart, setSearchInSecondPiChart] = useState('');
    const [searchInProgressChart, setSearchInProgressChart] = useState('');
    const [searchInLineChart, setSearchInLineChart] = useState('');
    // gaugebox dialog
    const [openDialog, setOpenDialog] = useState(false);
    // linechart+table dialog
    const [openDialogLinechart, setOpenDialogLinechart] = useState(false);
    // progress linechar dialog
    const [openDrillSelectionBox, setOpenDrillSelectionBox] = useState(false);
    const [tableGuageBox, setTableGuageBox] = useState([])
    const [searchTableGuageBox, setSearchTableGuageBox] = useState([])
    const [selectedGauges, setSelectedGauges] = useState([]);
    const [selectedSession, setSelectedSession] = useState([])
    const [selectedPiMetric, setSelectedPiMetric] = useState([])
    const [selectedSecondPiData, setSelectedSecondPiData] = useState([])
    const [selectedLineChartMetric, setSelectedLineChartMetric] = useState([])
    const [selectedProgressChartMetric, setSelectedProgressChartMetric] = useState([])
    const [selectedDriils, setSelectedDrills] = useState([]);
    const [numberOfSelection, setNumberOfSelection] = useState('')
    const [timeDuration, setTimeDuration] = useState('')
    const [drillKpis, setDrillKpis] = useState([])
    const [lineChartData, setLineChartData] = useState([])
    const [sessionDropdown, setSessionDropdown] = useState(1)
    const [selectedSessionName, setSelectedSessionName] = useState('')
    const [sessionName, setSessionName] = useState([]);
    useEffect(() => {
        setSessionDropdown(1)
        dispatch(fetchMetricList())
    }, [])
    useEffect(() => {
        if (!metricListData.isFetching && !metricListData.error && metricListData.payload.data) {
            setTableGuageBox(metricListData.payload.data.metrics);
            setSearchTableGuageBox(metricListData.payload.data.metrics)
            let tempdata = [...metricListData.payload.data.metrics].slice(0, 5);
            setSelectedGauges(tempdata);
            let sampleChartMetric = [...metricListData.payload.data.metrics].slice(1, 6);
            setSelectedLineChartMetric(sampleChartMetric);
            let tempPiData = [...metricListData.payload.data.metrics].slice(0, 1)
            setSelectedPiMetric(tempPiData)
            let tempSecondPiData = [...metricListData.payload.data.metrics].slice(1, 2);
            setSelectedSecondPiData(tempSecondPiData)
            let tempProgressMetric = [...metricListData.payload.data.metrics].slice(1, 2);
            setSelectedProgressChartMetric(tempProgressMetric)
        }
    }, [metricListData])
    useEffect(() => {
        if (playerList) {
            const temp = [...playerList].filter((item) => item.isSelected);
            setPlayers(temp)
            if (temp.length) {
                setPlayerName(temp[0].name)
            }
        }
    }, [playerList])
    useEffect(() => {
        setSessionDropdown(1);
        if (playerList) {
            const temp = [...playerList].filter((item) => item.isSelected);
            setPlayers(temp)
            if (temp.length) {
                setPlayerName(temp[0].name)
            }
        }
        if(selectedSession.length)
        if(sessionDropdown ===1 || sessionDropdown===3){
            let  tempSelected=selectedSession[0].id;
            setSelectedSessionName(tempSelected);
            const tempPlayers =[];
            [...selectedSession].forEach((item)=>{
                tempPlayers.push(item);

            })
        }
    }, [selectedSession])
    useEffect(() => {
        if (Array.isArray(selectedSession) && selectedSession.length && selectedDriils.length) {
            if (sessionDropdown === 1) {
                let temp = playerName;
                //guagebox
                const tempIndividualSession = individualSessionGuageBox([...selectedSession],
                    [...selectedDriils], selectedSessionName, temp);
                const tempIndividualTime = individualSessionTime([...selectedSession], [...selectedDriils],
                    selectedSessionName, temp);
                const tempNoOfDrills = tempIndividualSession.length;
                setNumberOfSelection(tempNoOfDrills)
                let tempTime = 0;
                tempIndividualTime.forEach((item) => {
                    let startTime = moment(item.startTime);
                    let endTime = moment(item.endTime);
                    let dif = moment.duration(endTime.diff(startTime));
                    tempTime += (dif.hours() * 60) + dif.minutes();
                })
                let sumOfTime = parseInt(tempTime / 60) + ":" + tempTime % 60;
                setTimeDuration(sumOfTime)
                let tempIndividualDrill = [];
                tempIndividualSession.forEach((item) => {
                    tempIndividualDrill.push(item)
                })
                setDrillKpis(tempIndividualDrill)
                let tempSession = selectedSession.map((item) => {
                    return {
                        id: item.id,
                        name: item.sessionName
                    }
                })
                setSessionName(tempSession)
                //linechar+table
                const tempdrill = individualLineChart([...selectedSession],
                    [...selectedDriils], selectedSessionName, temp);
                setLineChartData(tempdrill)
            }
            else if (sessionDropdown === 2) {
                let temp = [playerName]
                //guageboox
                const tempIndividualPeriod = individualPeriodGuageBox([...selectedSession], [...selectedDriils],
                    temp);
                const tempIndividualPeriodTime = individualPeriodTime([...selectedSession], [...selectedDriils],
                    temp);
                let tempIndividualPeriodDrill = []
                //guage
                tempIndividualPeriod.forEach((item) => {
                    tempIndividualPeriodDrill.push(item)
                })
                setDrillKpis(tempIndividualPeriodDrill)
                let noOfSession = [...selectedSession].map(((item) => (item.id))).length;
                setNumberOfSelection(noOfSession);
                let tempTime = 0;
                tempIndividualPeriodTime.forEach((item) => {
                    let startTime = moment(item.startTime);
                    let endTime = moment(item.endTime);
                    let dif = moment.duration(endTime.diff(startTime));
                    tempTime += (dif.hours() * 60) + dif.minutes();
                })
                let tempAvg = Math.round(tempTime / noOfSession);
                let avgOfTime = (parseInt(tempAvg / 60) + ":" + tempAvg % 60);
                setTimeDuration(avgOfTime);
                //period chart+table
                const tempDrill = individualPeriodLineChart([...selectedSession], [...selectedDriils], temp,noOfSession)
                let dataList = Object.keys(tempDrill).map((item) => tempDrill[item])
                setLineChartData(dataList)
            }
            else if (sessionDropdown === 3) {
                const tempSession = SessionGuageBox([...selectedSession], [...selectedDriils],
                    selectedSessionName, [...playerList]);
                //guagebox
                setDrillKpis(tempSession)
                const tempSessionTime = SessionTime([...selectedSession], [...selectedDriils],
                    selectedSessionName);
                let tempTime = 0;
                tempSessionTime.forEach((item) => {
                    let startTime = moment(item.startTime);
                    let endTime = moment(item.endTime);
                    let dif = moment.duration(endTime.diff(startTime));
                    tempTime += (dif.hours() * 60) + dif.minutes();
                })
                let sumOfTime = (parseInt(tempTime / 60) + ":" + tempTime % 60);
                setTimeDuration(sumOfTime)
                let noOfPlayers = [...playerList].filter((item) => item.isSelected === true).length
                setNumberOfSelection(noOfPlayers)
                //LineChart+table
                const tempSessionDrills = sessionLinechart([...selectedSession], [...selectedDriils],
                    selectedSessionName, [...playerList]);
                let dataList = Object.keys(tempSessionDrills).map((item) => tempSessionDrills[item])
                setLineChartData(dataList)
            }
            else if(sessionDropdown ===4)
            {
                const tempMultipleSession = MultipleSessionGuageBox([...selectedSession], [...selectedDriils],
                     [...playerList]);
                     setDrillKpis(tempMultipleSession)
                     let noOfSession = [...selectedSession].map(((item) => (item.id))).length;
                     setNumberOfSelection(noOfSession);
                     const tempMultipleSessionTime = MultipleSessionTime([...selectedSession], [...selectedDriils],
                       );
                       let tempTime = 0;
                       tempMultipleSessionTime.forEach((item) => {
                           let startTime = moment(item.startTime);
                           let endTime = moment(item.endTime);
                           let dif = moment.duration(endTime.diff(startTime));
                           tempTime += (dif.hours() * 60) + dif.minutes();
                       })
                       let tempAvg = Math.round(tempTime / noOfSession);
                       let avgOfTime = (parseInt(tempAvg / 60) + ":" + tempAvg % 60);
                       setTimeDuration(avgOfTime)
                     const tempMultipleSessionDrills = multipleSessionLinechart([...selectedSession], [...selectedDriils],
                         [...playerList]);
                    let dataList = Object.keys(tempMultipleSessionDrills).map((item) => tempMultipleSessionDrills[item])
                    setLineChartData(dataList)
            }
            else {
                const tempSquadPeriod = SquadPeriodGuageBox([...selectedSession], [...selectedDriils],
                    [...playerList]);
              
                setDrillKpis(tempSquadPeriod);
                let noOfSession = [...selectedSession].map(((item) => (item.id))).length;
                let noOfPlayers=[...playerList].map(((item) => (item.id))).length;
                setNumberOfSelection(noOfSession);
                const tempSquadeSessionTime = MultipleSessionTime([...selectedSession], [...selectedDriils],
                    );
                    let tempTime = 0;
                    tempSquadeSessionTime.forEach((item) => {
                        let startTime = moment(item.startTime);
                        let endTime = moment(item.endTime);
                        let dif = moment.duration(endTime.diff(startTime));
                        tempTime += (dif.hours() * 60) + dif.minutes();
                    })
                    let tempAvg = Math.round(tempTime / noOfSession);
                    let avgOfTime = (parseInt(tempAvg / 60) + ":" + tempAvg % 60);
                    setTimeDuration(avgOfTime)
                const tempSquadPeriodDrills = SquadPeriodLinechart([...selectedSession], [...selectedDriils],
                    [...playerList],noOfPlayers);
                    let dataList = Object.keys(tempSquadPeriodDrills).map((item) => tempSquadPeriodDrills[item])
                    setLineChartData(dataList)
            }
        }
    }, [selectedSession, playerName, sessionDropdown, selectedLineChartMetric,
        selectedSessionName, playerList, selectedDriils])
    const openLineChartDialogClose = () => {
        setOpenDialogLinechart(false);
    }
    const openGuageboxDialog = () => {
        setOpenDialog(false);
    }
    const searchWord = (value, key) => {
        let filterParams = {
            'keyWord': search, 'pichart_first': searchInFristPiChart,
            'pichart_second': searchInSecondPiChart, 'progressChart': searchInProgressChart,
            'lineChart': searchInLineChart
        };
        if (key === "search") {
            setSearch(value);
            filterParams['keyWord'] = value;
        }
        else if (key === "search_in_first") {
            setSearchInFristPiChart(value);
            filterParams['pichart_first'] = value;
        }
        else if (key === "search_progress_chart") {
            setSearchInProgressChart(value);
            filterParams['progressChart'] = value;
        }
        else if (key === "lineChart") {
            setSearchInLineChart(value);
            filterParams['lineChart'] = value;
        }
        else {
            setSearchInSecondPiChart(value);
            filterParams['pichart_second'] = value;
        }
        if (filterParams['keyWord'] === '' && filterParams['pichart_first'] === '' &&
            filterParams['pichart_second'] === '' && filterParams['progressChart'] === ''
            && filterParams['lineChart']) {
            setTableGuageBox(searchTableGuageBox);
        }
        else {
            const filter = handleSearchword(filterParams, searchTableGuageBox)
            setTableGuageBox(filter)
        }
    }
    const handleGuageBox = (data) => {
        setSelectedGauges(data)
    }
    const handleLineChart = (data) => {
        setSelectedLineChartMetric(data);
    }
    const handleSelectedSession = (data) => {
        setSelectedSession(data)
    }
    const handleSecondPichartMetrics = (data) => {
        setSelectedSecondPiData(data)
    }
    const handlePiChartMetrics = (data) => {
        setSelectedPiMetric(data)
    }
    const handleProgressChartMetrics = (data) => {
        setSelectedProgressChartMetric(data);
    }
    const handleSearchClear = () => {
        setTableGuageBox(searchTableGuageBox)
    }
    const handleselectedPlayers = (data) => {
        setPlayerList(data)
    }
    const handleSaveDrill = (data) => {
        setSelectedDrills(data)
    }
    const handleDrillSelectionClose = (data) => {
        setSelectedDrills(data)
        setOpenDrillSelectionBox(false);
    }
    return (
        <ThemeProvider theme={theme}>
            <div>
                {/* header section */}
                <Header
                    handleSelectedSession={handleSelectedSession}
                    handleselectedPlayers={handleselectedPlayers}
                />
                {/* <!-- Filter Sections --> */}
                <Box className="dashboard_box">
                    <Paper className=" body-bg" square>
                        <Grid container className=" container userProfile" justify="flex-end"
                            alignItems="center">
                            <Grid item className="userProfile">
                                <img src={require("../../images/sessions.png")} alt="sessions icon" />
                                <Select
                                    className="userProfile"
                                    sx={{
                                        minWidth: 170,
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: "transparent",
                                            backgroundColor: "transparent",
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: "transparent",
                                            backgroundColor: "transparent",
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: "transparent",
                                            backgroundColor: "transparent",
                                        },
                                        '.MuiSvgIcon-root ': {
                                            fill: "white !important",
                                        }
                                    }}
                                    MenuProps={MenuProps}
                                    value={sessionDropdown}
                                    onChange={(e) => setSessionDropdown(e.target.value)}
                                >
                                    <MenuItem value={1}>Individual Session</MenuItem>
                                    <MenuItem value={2}>Individual Period</MenuItem>
                                    <MenuItem value={3}>Session</MenuItem>
                                    <MenuItem value={4}>Multiple Session</MenuItem>
                                    <MenuItem value={5}>Squad Period</MenuItem>
                                </Select>
                            </Grid>
                            <div className="space-filter hidden-mobile hidden-tab"></div>
                            <Grid className="userProfile" item >
                                <Grid container className="dashboard_time">
                                    <Grid item>
                                        <img src={require("../../images/timer-line.png")} alt="timer icon" />
                                    </Grid>
                                    <Grid item>
                                        {(sessionDropdown === 1 || sessionDropdown === 3) ? 
                                        <p>Time :{timeDuration}</p> : null}
                                        {(sessionDropdown === 2 || sessionDropdown === 4 || sessionDropdown === 5) ? <p>AvgTime :{timeDuration}</p> : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <div className="space-filter hidden-mobile hidden-tab"></div>
                            <Grid className="userProfile dashboard_time " item  >
                                {(sessionDropdown === 1) ?
                                    <p>#Drills:{numberOfSelection}</p> : null
                                }
                                {(sessionDropdown === 2) ?
                                    <p>#Sessions:{numberOfSelection}</p> : null
                                }
                                {(sessionDropdown === 4 || sessionDropdown=== 5) ?
                                    <p>#Sessions:{numberOfSelection}</p> : null
                                }
                                {(sessionDropdown === 3) ?
                                    <p>#Players:{numberOfSelection}</p> : null
                                }
                            </Grid>
                            <div className={sessionDropdown === 4 || sessionDropdown ===5 ? "individual-space-select ":null}></div>
                            <Grid item>
                                <Grid container>
                                    <Grid item>
                                        <div className={sessionDropdown === 1 ? "individual-space-select "
                                            : "space-select"}></div>
                                    </Grid>
                                    {sessionDropdown === 1 || sessionDropdown === 2 ?
                                        <Grid item >
                                            <img src={require("../../images/player-name.png")} alt="player-nameicon" />
                                            <Select className="userProfile select-player-wrap"
                                                sx={{
                                                    minWidth: 100,
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        borderColor: "transparent",
                                                        backgroundColor: "transparent",
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: "transparent",
                                                        backgroundColor: "transparent",
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: "transparent",
                                                        backgroundColor: "transparent",
                                                    },
                                                    '.MuiSvgIcon-root ': {
                                                        fill: "white !important",
                                                    }
                                                }}
                                                onChange={(e) => setPlayerName(e.target.value)}
                                                value={playerName}
                                                displayEmpty
                                                MenuProps={MenuProps}
                                            >
                                                <MenuItem value="" >Player Name</MenuItem>
                                                {players.map((item,index) => (
                                                    <MenuItem value={item.name} key={index}>{item.player.firstName}{" "}{item.player.lastName}</MenuItem>
                                                ))}
                                            </Select>
                                        </Grid> : null}
                                    {sessionDropdown === 1 || sessionDropdown === 3 ?
                                        <Grid item >
                                            <img
                                                className="match_name"
                                                src={require("../../images/match_name.png")} alt="player-nameicon" />
                                            <Select className="userProfile select-player-wrap"
                                                sx={{
                                                    minWidth: 130,
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        borderColor: "transparent",
                                                        backgroundColor: "transparent",
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: "transparent",
                                                        backgroundColor: "transparent",
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: "transparent",
                                                        backgroundColor: "transparent",
                                                    },
                                                    '.MuiSvgIcon-root ': {
                                                        fill: "white !important",
                                                    }
                                                }}
                                                onChange={(e) => setSelectedSessionName(e.target.value)}
                                                value={selectedSessionName}
                                                displayEmpty
                                                MenuProps={MenuProps}
                                            >
                                                <MenuItem value="" >Sessions</MenuItem>
                                                {sessionName.map((item,index) => (
                                                    <MenuItem value={item.id} key={index}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </Grid> : null}
                                    <div className="space-datepicker hidden-mobile hidden-tab"></div>
                                    <Grid item className="dashboard_time">
                                        <IconButton 
                                        onClick={() => setOpenDrillSelectionBox(!openDrillSelectionBox)}>
                                            <img src={require("../../images/list-settings.png")} 
                                            alt="list settings" />
                                        </IconButton>
                                    </Grid>
                                    <DrillSelectionBox
                                        openDrillSelectionBox={openDrillSelectionBox}
                                        handleDrillSelectionClose={handleDrillSelectionClose}
                                        selectedSession={selectedSession}
                                        handleSaveDrill={handleSaveDrill}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Grid Box section */}
                        <Grid container className=" container merit-bg" justify="flex-end"  >
                            <Grid container >
                                <div className="space-info-icon "></div>
                                <div className="space-select-player hidden-mobile "></div>
                                <Grid item sx={{ pb: 2 }}>
                                    <IconButton onClick={() => setOpenDialog(!openDialog)}>
                                        <img src={require("../../images/info-btn.png")} alt="info button"
                                        />
                                    </IconButton>
                                </Grid>
                                <GaugeboxDialog searchWord={searchWord}
                                    tableGuageBox={tableGuageBox}
                                    selectedGauges={selectedGauges}
                                    openGuageboxDialog={openGuageboxDialog}
                                    openDialog={openDialog}
                                    search={search} handleGuageBox={handleGuageBox}
                                    handleSearchClear={handleSearchClear} />
                            </Grid>
                            <Grid container   >
                                {
                                    selectedGauges.map(item => (
                                        <GaugeBox key={item.id} gauge={item}
                                            drillKpis={drillKpis}
                                            sessionDropdown={sessionDropdown} 
                                            numberOfSessions={numberOfSelection}/>
                                    ))
                                }
                               
                            </Grid>
                            {/* LineChart+Table Section */}
                        </Grid>
                        <Grid container className=" container merit-bg bg-chart" justify="flex-end" >
                            <Grid container >
                                <div className="space-info-icon "></div>
                                <div className="space-select-player hidden-mobile "></div>
                                <Grid item sx={{ pt: 1 }} >
                                    <IconButton onClick={() => setOpenDialogLinechart(!openDialogLinechart)}>
                                        <img src={require("../../images/info-btn.png")} alt="info button" />
                                    </IconButton>
                                    {openDialogLinechart ?
                                        <LineChartDialog
                                            openDialogLinechart={openDialogLinechart}
                                            openLineChartDialogClose={openLineChartDialogClose}
                                            tableGuageBox={tableGuageBox}
                                            selectedLineChartMetric={selectedLineChartMetric}
                                            searchWord={searchWord}
                                            search={search}
                                            handleSearchClear={handleSearchClear}
                                            handleLineChart={handleLineChart}
                                        />
                                        : ""}
                                </Grid>
                            </Grid>
                            <div className="nav-header-div">
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className={displayChart ? "active nav-link" : "nav-link"} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
                                            type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => { setDisplayChart(true) }}>Combo Chart</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={!displayChart ? "active nav-link" : "nav-link"} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
                                            type="button" role="tab" aria-controls="pills-profile" aria-selected="true" onClick={() => { setDisplayChart(false) }}>Table</button>
                                    </li>
                                </ul>
                            </div>
                            <Grid container>
                                {displayChart !== false ?
                                    <Grid container>
                                        <Grid item lg={12} sm={12} xs={12}>
                                            <Paper className="bg-chart"  elevation={0} square>
                                                <Charts lineChartData={lineChartData}
                                                    selectedLineChartMetric={selectedLineChartMetric}
                                                    combo={"combo"}
                                                    sessionDropdown={sessionDropdown}
                                                    noOfSession={numberOfSelection}

                                                />
                                            </Paper>
                                        </Grid>
                                    </Grid> :
                                    <Grid item lg={12}>
                                        <Paper className="bg-chart"  elevation={0} square>
                                            <Tables lineChartData={lineChartData}
                                                selectedLineChartMetric={selectedLineChartMetric} />
                                        </Paper>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        {/* pichart section */}
                        <DashboardPiChart
                            searchWord={searchWord}
                            searchInFristPiChart={searchInFristPiChart}
                            tableGuageBox={tableGuageBox}
                            selectedPiMetric={selectedPiMetric}
                            selectedSecondPiData={selectedSecondPiData}
                            handlePiChartMetrics={handlePiChartMetrics}
                            handleSearchClear={handleSearchClear}
                            handleSecondPiChartMetrics={handleSecondPichartMetrics}
                            searchInSecondPiChart={searchInSecondPiChart}
                        />
                        {/* Progress chart*/}
                        <DashboardProgressChart
                            selectedProgressChartMetric={selectedProgressChartMetric}
                            searchWord={searchWord}
                            tableGuageBox={tableGuageBox}
                            searchInProgressChart={searchInProgressChart}
                            handleSearchClear={handleSearchClear}
                            handleProgressChartMetrics={handleProgressChartMetrics}
                        />
                    </Paper>
                </Box>
            </div>
        </ThemeProvider>
    )
}