import { dashboardActionType } from "../actions/dashboardAction"
const dataTemplate = {
    isFetching: false,
    payload: [],
    error: false,
    errorMessage: ""
}
const initialData = {
    sessionList: {...dataTemplate,allSessions:[]},
    progressSessionList: dataTemplate,
    piSessionList: dataTemplate
}
export const DashboardReducer = (state = initialData, action) => {
    switch (action.type) {
        //fetch session
        case dashboardActionType.FETCH_SESSION:
            return {
                ...state,
                sessionList: {
                    ...state.sessionList,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case dashboardActionType.FETCH_SESSION_SUCCESS:
                let allSessions=[...state.sessionList.allSessions,...action.payload.data.session_data]
            return {
                ...state,
                sessionList: {
                    ...state.sessionList,
                    isFetching: false,
                    payload: action.payload,
                    error: false,
                    allSessions
                }
            }
        case dashboardActionType.FETCH_SESSION_ERROR:
            return {
                ...state,
                sessionList: {
                    ...state.sessionList,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        case dashboardActionType.FETCH_SESSION_PROGRESS:
            return {
                ...state,
                progressSessionList: {
                    ...state.progressSessionList,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case dashboardActionType.FETCH_SESSION_PROGRESS_SUCCESS:
            return {
                ...state,
                progressSessionList: {
                    ...state.progressSessionList,
                    isFetching: false,
                    payload: action.payload,
                    error: false
                }
            }
        case dashboardActionType.FETCH_SESSION_PROGRESS_ERROR:
            return {
                ...state,
                progressSessionList: {
                    ...state.progressSessionList,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        case dashboardActionType.FETCH_SESSION_PI:
            return {
                ...state,
                piSessionList: {
                    ...state.piSessionList,
                    isFetching: true,
                    payload: [],
                    error: false
                }
            }
        case dashboardActionType.FETCH_SESSION_PI_SUCCESS:
            return {
                ...state,
                piSessionList: {
                    ...state.piSessionList,
                    isFetching: false,
                    payload: action.payload,
                    error: false
                }
            }
        case dashboardActionType.FETCH_SESSION_PI_ERROR:
            return {
                ...state,
                piSessionList: {
                    ...state.piSessionList,
                    isFetching: false,
                    payload: [],
                    error: true,
                    errorMessage: action.error
                }
            }
        default:
            return state;
    }
}