import React, { useState, useEffect } from 'react'
export const Tables = ({ lineChartData, selectedLineChartMetric }) => {
  const [selectedTableCol, setSelectedTableCol] = useState([])
  useEffect(() => {
    const newColumn = { id: "name", label: "Drill" }
    const newArray = [{ ...newColumn }, ...selectedLineChartMetric]
    setSelectedTableCol(newArray)
  }, [selectedLineChartMetric, lineChartData])
  return (
    <div className='Chart-table_Scroll'>
      <table className="table Chart-table ">
        <thead
        //className='hidden-mobile'
        >
          <tr className='table_head'>
            {selectedTableCol.map((col, index) => (

              <th>{col.label}</th>

            ))}
          </tr>
        </thead>
        <tbody className='table_body Chart-table_Scroll_mob ' >
          {lineChartData.map((row, index) => (
            <tr key={index}>
              {selectedTableCol.map((col, index) => (
                <td>{!isNaN(row[col.id]) ? Math.round(row[col.id]).toFixed() : row[col.id]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

