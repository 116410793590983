import React, { useState, useEffect } from 'react'
import { Avatar, Badge, Card, CardActionArea, CardContent, Dialog, Grid, IconButton } from '@mui/material'
import DoneAllIcon from '@mui/icons-material/DoneAll';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CloseIcon from '@mui/icons-material/Close';
import '../../styles/style.css'
import { CardButton, CloseIconButton, stringAvatar } from '../../styles/styles'
export const PlayerListDialog = (props) => {
    //card payer+squad
    const [showPlayer, setShowPlayer] = useState(true);
    const [players, setPlayers] = useState([])
    const [showSelect, setShowSelect] = useState(false);
   
    useEffect(() => {
        setPlayers(props.playersData)
    }, [props.playersData]);
    const handleClose = () => {
        props.openDialogClose();
    }
    const handleSave = (name) => {
        const tempSelectedPlayers = JSON.parse(JSON.stringify([...players]))
        const tempPlayers = tempSelectedPlayers.map((item, index) => {
            if (item.name === name) {
                item.isSelected = !item.isSelected;
            }
            return item;
        })
        
        setPlayers(tempPlayers)
        props.handleselectedPlayers(tempPlayers)
        props.handlePlayer(tempPlayers)
    }
    const handleSelect = () => {
        setShowSelect(!showSelect);
        const tempSelectedPlayers = JSON.parse(JSON.stringify([...players]))
        const tempPlayers = tempSelectedPlayers.map((item) => {
            if (showSelect) {
                item.isSelected = true;
            }
            else {
                item.isSelected = false;
            }
            return item;
        })
        setPlayers(tempPlayers)
        props.handleselectedPlayers(tempPlayers)
        props.handlePlayer(tempPlayers)
    }
    const handlePlayer = () => {
        props.openDialogClose()
    }
    return (
        <Dialog
            scroll={'body'}
            open={props.openPlayerDialog}
            onClose={() => { props.openDialogClose() }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            minWidth="md"
        >
            <Grid container >
                <Grid item className="card-dialog">
                    <Card className="card-dialog">
                        <CardContent>

                            <Grid container justify="flex-end" alignItems="center" className="whiteborderbtn">
                                <Grid item>
                                    <IconButton onClick={() => { handleSelect() }} >

                                        <DoneAllIcon className={!showSelect ? "show_active" : null} sx={{ color: "white" }} />
                                    </IconButton>
                                </Grid>
                                <div className="space-select-checkbox-drill hidden-mobile"></div>
                                <div className="divmarginbtm">
                                    <ul className="nav nav-button mb-3" id="pills-tab" role="tablist">
                                        <li className="nav-item  limarginbtm" role="presentation">
                                            <div className={showPlayer ? "nav-button-active" : "nav-button"} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
                                                role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => { setShowPlayer(true) }}>Player's</div>
                                        </li>
                                        <div className="nav-button-active"></div>
                                        <div className="space-filter"></div>
                                        <li className="nav-item limarginbtm" role="presentation">
                                            <div className={!showPlayer ? "nav-button-active" : "nav-button"} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
                                                role="tab" aria-controls="pills-profile" aria-selected="true" onClick={() => { setShowPlayer(false) }}>Squad</div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="space-select-checkbox hidden-mobile"></div>
                                <div className="space-btw-grid"></div>
                                <Grid item >
                                    <IconButton onClick={() => { handleClose() }}>
                                        <CloseIcon sx={{ color: "white" }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Grid container justify="flex-end"
                                alignItems="center" sx={{ mt: 10, ml: 2, mr: 2 }}>
                                {players.map((item, index) => (
                                    <Grid item justify="flex-end"
                                        alignItems="center" sx={{ ml: 2, mb: 2 }} lg={1} key={index}>

                                        <Badge
                                            onClick={() => handleSave(item.name)}
                                            overlap="circular"
                                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                            badgeContent={
                                                <CloseIconButton
                                                    className=
                                                    {!item.isSelected ? "hide_close_icon" : ""}
                                                    sx={{ backgroundColor: "#A622BB", width: 12, height: 12 }}>
                                                    <HighlightOffIcon sx={{ color: "white" }} fontSize="small" />
                                                </CloseIconButton>
                                            }
                                        >
                                            <Avatar {...stringAvatar(item.player.firstName+" "+item.player.lastName)} />
                                            {/* <Avatar
                                                {...stringAvatar(item.player.name)}
                                            >{item.player.shortName}</Avatar> */}
                                        </Badge>

                                    </Grid>
                                ))}
                            </Grid>

                        </CardContent>
                        <CardActionArea >
                            <Grid >
                                <CardButton variant="contained"
                                    onClick={() => { handlePlayer() }}
                                    sx={{ ml: 50, mb: 2 }}>
                                    Save
                                </CardButton>
                            </Grid>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </Dialog>

    )
}