import { all, put, takeLatest } from "redux-saga/effects";
import { fetchPlayers } from "../../services/playerServices";
import { fetchPlayerListError, fetchPlayerListSuccess, playerActionType } from "../actions/playerAction";

// fetch players
function* _fetchPlayerListData(action) {
    try {
        const players = yield (fetchPlayers(action.id));
        yield put(fetchPlayerListSuccess(players))

    }
    catch (e) {
        yield put(fetchPlayerListError("error"))
    }
}
function* fetchPlayerListData() {
    yield takeLatest(playerActionType.FETCH_PLAYER_LIST, _fetchPlayerListData)
}
export function* PlayerSaga() {
    yield all([fetchPlayerListData()])
}